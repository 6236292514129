import PropTypes from "prop-types";
import "./searchDropdown.css";
import { Grid, Row, Col } from "react-bootstrap";
import Autosuggest from "react-autosuggest";
import theme from "./theme.css";
import * as Settings from "../Config.json";
import React, { Component } from "react";
let Restaurants = [];
let FoodCategory = [];

function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function getSuggestions(value) {
    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === "") {
        return [];
    }

    const regex = new RegExp("^" + escapedValue, "i");
    if (Restaurants.length == 0) {
        return FoodCategory.filter(language =>
            regex.test(language.food_catagory)
        );
    } else {
        return Restaurants.filter(language =>
            regex.test(language.restaurant_name)
        );
    }
}

function getSuggestionValue(suggestion) {
    if (Restaurants.length == 0) {
        return suggestion.food_catagory;
    } else {
        return suggestion.restaurant_name;
    }
}

function renderSuggestion(suggestion) {
    if (Restaurants.length == 0) {
        return <span>{suggestion.food_catagory}</span>;
    } else {
        return <span>{suggestion.restaurant_name}</span>;
    }
}

class SearchDropDownNav extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            value: "",
            suggestions: []
        };
        this.Search = this.Search.bind(this);
        this.shiftCategory = this.shiftCategory.bind(this);
        this.handleToClose = this.props.handleToClose.bind(this);
    }

    componentDidMount() {
        const that = this;
        fetch(Settings.baseurl+"/api/autocompletecategory/")
            .then(function(response) {
                return response.json();
            })
            .then(function(jsonData) {
                return jsonData;
            })
            .then(function(jsonStr) {
                FoodCategory = jsonStr;
            });
    }

    getInfo(event) {
        let val = event.target.value;
        if (val == "r") {
            FoodCategory = [];
            fetch(Settings.baseurl+"/api/priority_rest/")
                .then(function(response) {
                    return response.json();
                })
                .then(function(jsonData) {
                    return jsonData;
                })
                .then(function(jsonStr) {
                    Restaurants = jsonStr;
                });
        } else if (val == "f") {
            Restaurants = [];
            fetch(Settings.baseurl+"/api/autocompletecategory/")
                .then(function(response) {
                    return response.json();
                })
                .then(function(jsonData) {
                    return jsonData;
                })
                .then(function(jsonStr) {
                    FoodCategory = jsonStr;
                });
        }
    }
    shiftCategory(e) {
        this.props.show = false;
        e.preventDefault();
    }
    handleToClose(a) {
        return a;
    }
    Search() {
        let url = window.location.origin;
        if (FoodCategory.length == 0) {
            fetch(
                Settings.baseurl+"/api/restaurant/?search=" +
                    this.state.value
            )
                .then(function(response) {
                    return response.json();
                })
                .then(function(jsonData) {
                    return jsonData;
                })
                .then(function(jsonStr) {
                    jsonStr.slice(0).map((element, key) => {
                        return window.location.replace(
                            url + "/restaurant/" + element.id
                        );
                    });
                });
        } else if (Restaurants.length == 0) {
            let aa = fetch(
                Settings.baseurl+"/api/foodcategory/?search=" +
                    this.state.value
            )
                .then(function(response) {
                    return response.json();
                })
                .then(function(jsonData) {
                    return jsonData;
                })
                .then(function(jsonStr) {
                    jsonStr.slice(0).map((element, key) => {
                        return window.location.replace(
                            url + "/foodcategorylist/" + element.id
                        );
                    });
                });
        }
    }
    onChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };
    render() {
        const { show } = this.props;
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: "Search...",
            value,
            onChange: this.onChange
        };
        return (
            <div  >
                {show ? (
                    <div   className="nav-overlay-backgrounds seraching">
                        <div className="nav-overlay-contents">
                            <Grid className="searchgrid">
                                <Row>
                                    <Col
                                        style={{ padding: "0px" }}
                                        md={3}
                                        sm={3}
                                        xs={3}
                                    >
                                        <select
                                            onChange={this.getInfo}
                                            className="selectoption"
                                        >
                                            <option value="f">
                                                Food category
                                            </option>
                                            <option value="r">
                                                Restaurant
                                            </option>
                                        </select>
                                    </Col>
                                    <Col
                                        style={{ padding: "0px" }}
                                        md={7}
                                        sm={7}
                                        xs={7}
                                    >
                                        <Autosuggest
                                            suggestions={suggestions}
                                            onSuggestionsFetchRequested={
                                                this.onSuggestionsFetchRequested
                                            }
                                            onSuggestionsClearRequested={
                                                this.onSuggestionsClearRequested
                                            }
                                            getSuggestionValue={
                                                getSuggestionValue
                                            }
                                            renderSuggestion={renderSuggestion}
                                            inputProps={inputProps}
                                        />
                                    </Col>
                                    <Col
                                        style={{ padding: "0px" }}
                                        md={2}
                                        sm={2}
                                        xs={2}
                                    >
                                        <div
                                            onClick={this.Search}
                                            style={{
                                                height: "40px",
                                                width: "50%",
                                                borderTopRightRadius: "0",
                                                borderBottomRightRadius: "20px",
                                                border:
                                                    "1px solid rgb(230, 119, 85)",
                                                paddingTop: "10px",
                                                backgroundColor:
                                                    "rgb(232, 99, 46)"
                                            }}
                                        >
                                            {" "}
                                            <i
                                                style={{ color: "white" }}
                                                onClick={this.Search}
                                                className="fa fa-search"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Grid>

                            <div className="nav-overlay-backgrounds" onClick={() =>
                this.handleToClose(
                    false
                )
            } style={{ marginTop:"20px",width:"100%",height:"1000px"}}></div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

SearchDropDownNav.propTypes = {
    show: PropTypes.bool.isRequired
};

export default SearchDropDownNav;
