import React, { Component, PropTypes } from "react";
import { Col, Image, Button } from "react-bootstrap";

import "./HomePickOfTheDay.css";
import * as NumericInput from "react-numeric-input";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CartModel from "./CartModel";
import * as actionCreators from "../actions";
import NonVeg from "../images/non-veg.png"
import Veg from "../images/veg.png"
class FoodCategoryMenu extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            numInput: 1,
            cart: [],
            showCartButton: false,
            isLoading: false,
            openState: false
        };
        this.onChangeInputHandler = this.onChangeInputHandler.bind(this);
        this.addToCart = this.addToCart.bind(this);
    }

    checkType(typeId) {
        let picType = "";
        if (typeId === 2) {
            picType =
                "https://png.icons8.com/color/50/000000/non-vegetarian-food-symbol.png";
        }
        if (typeId === 1) {
            picType =
                "https://png.icons8.com/color/50/000000/vegetarian-food-symbol.png";
        }
        return picType;
    }

    onChangeInputHandler(value) {
        this.setState({ numInput: value });
    }

    addToCart(e, pic, quantity) {

       if(pic.available === true){
        alert("Currently, Item not available")
       }else{
        if(pic.rest_closed){
            alert("Sorry! The restaurant "+pic.restaurant_name+" is closed for today.")
          }else{
            var currentTime = new Date();
            var hrs = ('0'+currentTime.getHours()).substr(-2);
            var t = hrs + ":" + currentTime.getMinutes();
        //    console.log('time '+t);
            
            if(t >= pic.rest_open_time && t <= pic.rest_close_time){

        var tt = this.props.cartItems;
        if(tt === null || tt === undefined){
            var packing_charge = pic.packing_charge === "" || pic.packing_charge === null ? 0 : parseInt(pic.packing_charge);
            var gst_charge = pic.gst_value === "" || pic.gst_value === null ? 0 : parseInt(pic.gst_value);
            var sub_total = parseInt(quantity)*(packing_charge+parseInt(pic.price) + (gst_charge*(parseInt(pic.price)+packing_charge)/100))
           
            var sub_total = Math.ceil(sub_total);
    
          
            
            const object = {quantity, sub_total, ...pic};
               const value = this.props.actions.addItem(object);
               this.setState({ numInput: 1 });
        }else{
            var t1 =    Object.keys(tt).map(key => {
              //  console.log("test "+JSON.stringify(key))
               if( pic.restaurant !== tt[key].restaurant){
                 //  console.log("if1")
                  // this.setState({preRest: item.restaurant_name})
                   return false;
                  
                 }else{
                   return true;
                 }
           });
      
    
         // console.log("flagggg "+t1);
           if(t1.includes(false)){
             alert("Only one order per Restaurant is allowed!\n\n Please remove the previous selection from your Cart.")
           }else{
           // console.log(JSON.stringify(pic));
            var packing_charge = pic.packing_charge === "" || pic.packing_charge === null ? 0 : parseInt(pic.packing_charge);
            var gst_charge = pic.gst_value === "" || pic.gst_value === null ? 0 : parseInt(pic.gst_value);
            var sub_total = parseInt(quantity)*(packing_charge+parseInt(pic.price) + (gst_charge*(parseInt(pic.price)+packing_charge)/100))
           // console.log("sub total "+Math.ceil(sub_total));
            //var quant = { quantity: quantity, sub_total: Math.ceil(sub_total)}
           // console.log("qunatity "+JSON.stringify(quantity));
            var sub_total = Math.ceil(sub_total);

          
            const object = { ...pic, quantity, sub_total };
            // console.log("objects"+JSON.stringify(object));
               const value = this.props.actions.addItem(object);
               this.setState({ numInput: 1 });
           }
        }
    }
    else {
      alert("Sorry! Restaurant closed.")
    }
    
  }

       }
    }

    render() {
        const { items } = this.props;
        const totalPrice = items.price * this.state.numInput;
        const picType = this.checkType(items.food_type);

        /*
    {
      this.state.openState ? (
        <CartModel passState={this.state.openState} />
      ) : null;
    }*/
        return (
            <tr className="foodcat-trow">
                <td className="table-wide">
                {items.food_type === 1 ? <img
                        src={Veg}
                        className="pickofday-food-type"
                        alt="no"
                    />:<img
                    src={NonVeg}
                    className="pickofday-food-type"
                    alt="no"
                />}{" "}
                    &nbsp;
                    {items.food_name}
                </td>

                <td className="table-wide">₹{totalPrice}</td>
                <td className="table-wide">
                    <div className="num-input">
                        <NumericInput
                            mobile
                            className="form-control"
                            min={1}
                            max={20}
                            value={this.state.numInput}
                            onChange={this.onChangeInputHandler}
                        />
                    </div>
                </td>
                <td className="table-wide">
                    <Button
                        bsStyle="success"
                        bsSize="small"
                        className="pull-right"
                        onClick={e => {
                            this.addToCart(e, items, this.state.numInput);
                        }}
                    >
                        Add
                    </Button>
                </td>
            </tr>
        );
    }
}

FoodCategoryMenu.propTypes = {};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actionCreators, dispatch)
});
const mapStateToProps = state => {
    return {
        cartItems: state.cart
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FoodCategoryMenu);
