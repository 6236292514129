import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import PropTypes from "prop-types";
import { Button, Modal, ButtonToolbar, Image, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Object } from "core-js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../actions";
import { LoginDialog, SignUpDialog } from "./Login";
import CustomNavbar from "./CustomNavbar";

class CartTable extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            rows: [],
            showRegister: false,
           
        };
        this.removeItem = this.removeItem.bind(this);
        this.close = this.close.bind(this);
        this.handleToUpdate = this.props.handleToUpdate.bind(this);
    }

  


    /*-----------LOGIN SIGNUP------------------*/
    logIn() {
        this.props.actions.loginRequest();
        this.setState({ showRegister: false });
        //return <Redirect to="/billingform" push={true} />
        //return window.location.href = '/billingform';
    }

    handleSelect() {
        this.logIn();
        this.handleToUpdate(false);
    }
    close() {
        this.props.actions.loginReset();
        this.setState({ showRegister: false });
    }
    /*-----------END LOGIN SIGNUP--------------*/

    removeItem(e) {
        //localStorage.removeItem(key);
        const rows = [...this.state.rows];
        // rows.splice(key, 1)
        //  this.setState({ rows })
    }

    render() {

        const { data, offerData, restDetails } = this.props;
        const {
            loggedIn,
            initiateLoggedIn,
            username
        } = this.props.authentication;
        var totalQuant = 0;
        var totalPrice = 0;
        var a = [];

        data !== null &&
            Object.keys(data).map(key => {
                totalQuant = totalQuant + data[key].quantity;
            });

        var currentTime = new Date();
        var t = currentTime.getHours() + ":" + currentTime.getMinutes();


        var totalPackCharge = 0;
        var totalQuantityPackCharge = 0;
        var deliveryCharge = 0;
        var gst_percent_total = 0;
        if (totalQuant !== 0) {
            return (
                <Fragment>
                     
                    <Table>
                  
                        <tbody>
                            {Object.keys(data).map(key => {
                                a.push(data[key].restaurant);
                                totalPrice =
                                    totalPrice +
                                    data[key].price * data[key].quantity;

                                var uniques = a.filter(function (item, i, ar) {
                                    return ar.indexOf(item) === i;
                                });

                                /* if (uniques.length > 2) {
                                     deliveryCharge = uniques.length * 10 + 10;
                                 } else {
                                     deliveryCharge = 30;
                                 }*/

                                if (data[key].packing_charge) {
                                    totalPackCharge =
                                        totalPackCharge +
                                        data[key].packing_charge *
                                        data[key].quantity;
                                    totalQuantityPackCharge =
                                        totalQuantityPackCharge +
                                        data[key].quantity;
                                }
                                //packCharge = totalPackCharge*totalQuantityPackCharge;

                                // totalPackCharge = totalPackCharge+totalPackCharge;
                                if (data[key].gst_value) {
                                    gst_percent_total =
                                        gst_percent_total +
                                        (data[key].gst_value *
                                            (data[key].quantity *
                                                data[key].price +
                                                data[key].quantity *
                                                data[key].packing_charge)) /
                                        100;
                                    // gst_percent_total = gst_percent_total + (data[key].gst_value*data[key].packing_charge)/100;
                                }
                                return (
                                    <tr>
                                        <td >
                                            {data[key].food_name}
                                        </td>
                                        <td >
                                            {data[key].quantity}
                                        </td>
                                        <td style={{ paddingLeft: 48 }}>
                                            ₹ {data[key].price}

                                            {data[key].offer !== null ? <span>
                                                <i style={{ paddingLeft: 8, fontSize: 10, color: "green" }}>
                                                    ( {data[key].offer}% OFF )
                                                </i><br />
                                            </span> : <br />}

                                            {data[key].packing_charge
                                                ? <span>
                                                    <i>
                                                        {"Packing Charge ₹ " +
                                                            data[key]
                                                                .packing_charge +
                                                            " Per Item"}
                                                    </i><br />
                                                </span> : ""}

                                            {data[key].gst_value
                                                ?
                                                <span>
                                                    <i>
                                                        {"GST extra " +
                                                            data[key].gst_value +
                                                            "%"}

                                                    </i>
                                                </span> : ""}
                                        </td>
                                        <td className="">
                                            <Button
                                                bsSize="xsmall"
                                                onClick={() => {
                                                    this.props.actions.removeItems(
                                                        key
                                                    );
                                                }}
                                            >
                                                <i className="fa fa-trash-o fa_custom fa-1x" />
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <Table style={{ width: "70%" }}>
                        <tbody>
                           
                                <tr >
                                    <td className="priceWidthTotal">
                                        Packing Charge:-
                                        <br />
                                       {totalQuantityPackCharge !== 0 ?
                                        <span>
                                            Quantity: {totalQuantityPackCharge}
                                        </span>: "" } 
                                    </td>
                                    <td className="priceWidthTotal">
                                        ₹ {totalPackCharge}
                                    </td>
                                </tr>
                            
                           
                                <tr >
                                    <td className="priceWidthTotal">GST :-</td>
                                    <td className="priceWidthTotal">
                                        ₹ {gst_percent_total}
                                    </td>
                                </tr>
                                {/*
                                    this.props.sameDeliveryFlag ?
                                    <tr >
                                    <td className="priceWidthTotal">Delivery Charge :-</td>
                                    <td className="priceWidthTotal">
                                        ₹ {this.props.sameDeliveryCharge}
                                    </td>
                                </tr>: (restDetails.restDetails.gst_number !== "" ? <tr >
                                    <td className="priceWidthTotal">Delivery Charge :-</td>
                                    <td className="priceWidthTotal">
                                        ₹ {restDetails.restDetails.gst_number}
                                    </td>
                                </tr>: "")*/}
                                {
                                        Math.round(
                                            totalPackCharge +
                                            totalPrice +
                                            gst_percent_total
                                        ) > parseInt(offerData[0]['min_amout']) ? (<tr>
                                            <td className="priceWidthTotal">Delivery Charge :-</td>
                                            <td className="priceWidthTotal">
                                                ₹ 0
                                            </td>
                                        </tr>) : ((t >= offerData[0]['time_from'] && t <= offerData[0]['time_upto']) ? (<tr>
                                            <td className="priceWidthTotal">Delivery Charge :-</td>
                                            <td className="priceWidthTotal">
                                                ₹ 0
                                            </td>
                                        </tr>) : (this.props.sameDeliveryFlag ? <tr>
                                            <td className="priceWidthTotal">Delivery Charge :-</td>
                                            <td className="priceWidthTotal">
                                                ₹ {this.props.sameDeliveryCharge}
                                            </td>
                                        </tr> : (restDetails.restDetails.gst_number !== "" ? <tr>
                                            <td className="priceWidthTotal">Delivery Charge :-</td>
                                            <td className="priceWidthTotal">
                                                ₹ {restDetails.restDetails.gst_number}
                                            </td>
                                        </tr> : <tr>
                                            <td className="priceWidthTotal">Delivery Charge :-</td>
                                            <td className="priceWidthTotal">
                                                ₹ 0
                                            </td>
                                        </tr>)))
                                    }
                            <tr>
                                <td>
                                    <b>Total:- </b>
                                </td>
                                <td style={{ paddingLeft: -10 }}>
                                    <b>
                                        ₹{" "}
                                        {Math.round(
                                            totalPackCharge +
                                            totalPrice +
                                            gst_percent_total +
                                            parseInt(Math.round(
                                                totalPackCharge +
                                                totalPrice +
                                                gst_percent_total
                                            ) > parseInt(offerData[0]['min_amout']) ? ('0') : ((t >= offerData[0]['time_from'] && t <= offerData[0]['time_upto']) ? ('0') : (this.props.sameDeliveryFlag ? this.props.sameDeliveryCharge : (restDetails.restDetails.gst_number !== "" ? restDetails.restDetails.gst_number : '0'))))
                                        )}
                                    </b><br></br>
                                    {
                                        Math.round(
                                            totalPackCharge +
                                            totalPrice +
                                            gst_percent_total
                                        ) > parseInt(offerData[0]['min_amout']) ? (<span><i style={{ fontSize: 13, color: 'green' }}>Your total is above ₹ 300. Free Delivery eligible</i></span>) : ((t >= offerData[0]['time_from'] && t <= offerData[0]['time_upto']) ? (<span><i style={{ fontSize: 13, color: 'green' }}>Free delivery between {offerData[0]['_from']} and {offerData[0]['_upto']}. Place Order Now.</i></span>) : (this.props.sameDeliveryFlag ? "" : (restDetails.restDetails.gst_number !== "" ? "" : <span><i style={{ fontSize: 13 }}>Excluding Delivery Charge</i></span>)))
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                    {!loggedIn ? (
                        <Button
                            bsStyle="success"
                            onClick={() => this.handleSelect(this.props)}
                        >
                            {" "}
                            Place Order Login
                            <LoginDialog
                                show={
                                    this.props.authentication.initiateLoggedIn
                                }
                                close={this.close}
                            />
                        </Button>
                    ) : (
                            <Link to="/billingform">
                                <Button
                                    bsStyle="success"
                                    onClick={() => this.handleToUpdate(false)}
                                >
                                    Place Order
                            </Button>
                            </Link>
                        )}
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <div className="cart-empty-div">
                        <img
                            className="emptyCart"
                            alt="Taste of Imphal"
                            //src="/assets/cart-empty.png"
                            src="https://www.tasteofimphal.com/assets/cart-empty.png"
                        />
                    </div>
                </Fragment>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        authentication: state.authentication
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CartTable);
