import * as Settings from "../Config.json";

const FacebookSignIn = {
    LoadSDK: function() {
        window.fbAsyncInit = function() {
            window.FB.init({
                appId: Settings.facebook_app_id,
                cookie: true, // enable cookies to allow the server to access
                // the session
                status: true, // check login status
                xfbml: true, // parse social plugins on this page
                version: "v2.10" // use version 2.1
            });
            //FB.AppEvents.logPageView();
        };

        // Load the facebook SDK asynchronously

        //<div id="fb-root"></div>
        (function(d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src =
                "https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v2.10&appId=567750043599743";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
    }
};

export default FacebookSignIn;
