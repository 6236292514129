import React, { Component } from "react";
import "./Home.css";
import {
    Grid,
    Row,
    Col,
    Table,
    Image,
    Button,
    Container
} from "react-bootstrap";
import CarousalHome from "./CarousalHome";
import HomePickOfTheDay from "./HomePickOfTheDay";
import HomeRestaurantList from "./HomeRestaurantList";
import ReactGA from "react-ga";
export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 1
        };

        this.doIncrement = this.doIncrement.bind(this);
        this.doDecrement = this.doDecrement.bind(this);
    }

    doDecrement() {
        if (this.state.value > 1) {
            this.setState(prevState => ({ value: prevState.value - 1 }));
        }
    }

    doIncrement() {
        if (this.state.value < 30) {
            this.setState(prevState => ({ value: prevState.value + 1 }));
        }
    }

    render() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        return (
            <div>
                <br />
                <br />
                <br />
                <CarousalHome />
                <Grid>
                    <Row className="home-banner-phon-time">
                        <Col md={12}>
                            <div
                                style={{ display: "flex" }}
                                className="home-banner-phone-time"
                            >
                                <div
                                    style={{ textAlign: "right", width: "45%" }}
                                >
                                    <p
                                        style={{
                                            color: "white",
                                            fontSize: "18px",
                                            paddingTop: "4px"
                                        }}
                                        className="fa fa-clock-o"
                                    >
                                        {" "}
                                        11AM-7PM &nbsp;&nbsp;
                                    </p>
                                </div>

                                <div style={{ alignContent: "left" }}>
                                    <a
                                        href="tel:+917085063393"
                                        className="phone-text1"
                                    >
                                        {" "}
                                        <i className="fa fa-phone" />
                                        &nbsp;+917085063393 /
                                    </a>

                                    <a
                                        href="tel:+917641032814"
                                        className="phone-text2"
                                    >
                                        +917641032814
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Grid>

                <HomePickOfTheDay />
                <HomeRestaurantList />
            </div>
        );
    }
}
