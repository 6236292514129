import React, { Component } from "react";
import { Grid, Row, Col, Table, Button } from "react-bootstrap";
import "./RestaurantDetails.css";
import "./otpverify.css";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { callBackend } from "./utils";
import * as Settings from "../Config.json";
import queryString from "query-string";
class PaymentSuccess extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {};
    }
    componentDidMount() {
        const values = queryString.parse(this.props.location.search);

        if (values.payment_status === "Failed") {
            alert("Payment Failed");
            setTimeout(this.redirectToHome, 2000);
        } else {
            let headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("cache-control", "no-cache");

            //get login user information
            var user = JSON.parse(localStorage.getItem("user"));

            //var user_key = user.key;
            //var user_login_name = user.username;
            var address = localStorage.getItem("addressInfo");
            var cartItems = localStorage.getItem("cartItemss");
            var totalPrice = localStorage.getItem("totalPrice");
            var paymode = localStorage.getItem("paymentMode");
            var addressphone = JSON.parse(localStorage.getItem("addressInfo"));

            let datapost = {
                total_price: totalPrice,
                address: address,
                card_items: cartItems,
                payment_mode: paymode,
                user_key: user.key,
                request_id: values.payment_id,
                success_id: values.payment_request_id,
                status: true
            };

            callBackend(
                Settings.baseurl +"/paymentsubmit/",
                response => {
                    //alert("..."+response);
                    if (typeof window !== "undefined") {
                        //window.location.href = window.location.origin
                        alert(response.message);
                        localStorage.removeItem("item");
                        //window.location.href = window.location.origin
                        setTimeout(this.redirectToHome, 2000);
                    }
                },
                headers,
                datapost,
                "POST"
            );
        }
    }

    redirectToHome() {
        window.location.href = window.location.origin;
    }

    render() {
        const values = queryString.parse(this.props.location.search);

        return (
            <div>
                {values.payment_status === "Failed" ? (
                    <Grid
                        style={{
                            height: "630px",
                            marginTop: " 70px",
                            paddingBottom: " 100px",
                            backgroundColor: "#F6F6F6  ",
                            textAlign: "center",
                            paddingTop: "80px"
                        }}
                    >
                        <h3>Payment Failed</h3>
                    </Grid>
                ) : (
                    <Grid
                        style={{
                            height: "630px",
                            marginTop: " 70px",
                            paddingBottom: " 100px",
                            backgroundColor: "#F6F6F6  ",
                            textAlign: "center",
                            paddingTop: "80px"
                        }}
                    >
                        <h3>Thank you! We will contact you soon</h3>
                        <h4>Your Payment ID is {values.payment_id}</h4>
                    </Grid>
                )}
            </div>
        );
    }
}

export default PaymentSuccess;
