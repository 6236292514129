import React from "react";
import { FormGroup, Button, Row, Col, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FieldGroup, validateInput, callBackend } from "./utils";
import "./Login.css";
import * as Settings from "../Config.json";

import * as actionCreators from "../actions";
// test vbndfddfgfd
import GoogleSignIn from "./GoogleSignIn";
// import FacebookSignIn from './FacebookSignIn';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: this.props.show,
            errors: {},
            isSignUp: this.props.isSignUp,
            username: "",
            password1: "",
            password2: "",
            email: ""
        };
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.onChange = this.onChange.bind(this);
        this.facebookLogin = this.facebookLogin.bind(this);
        this.googleLogin = this.googleLogin.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.changeToSignup = this.changeToSignup.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ showModal: nextProps.show });
    }
    /*
  componentDidMount(nextProps) {
    this.setState({ showModal: nextProps.show });
  } */

    changeToSignup(e) {
        e.preventDefault();
        this.setState({ isSignUp: true });
    }

    logInSuccess(user) {
        localStorage.setItem("user", JSON.stringify(user));
        this.props.actions.login(user);
    }

    onSubmit(e) {
        e.preventDefault();
        const { isSignUp } = this.props;
        if (isSignUp) {
            if (this.isValid(this.state)) {
                const headers = new Headers();
                headers.append("Content-Type", "application/json");
                headers.append("cache-control", "no-cache");
                const opts = {
                    username: this.state.username,
                    password1: this.state.password1,
                    password2: this.state.password2,
                    email: this.state.email
                };
                callBackend(
                    Settings.endpoints.registration,
                    response => {
                        const errors = {};

                        if (response.key) {
                            const user = {};
                           // console.log(JSON.stringify(response.key))
                            user.username = this.state.username;
                            user.key = response.key;
                            user.auth = "default";
                            this.close();
                            this.logInSuccess(user);
                        } else {
                            // alert(JSON.stringify(response));
                            errors.username = response.username;
                            errors.password1 = response.password1;
                            errors.password2 = response.password2;
                            errors.email = response.email;

                            this.setState({ errors });
                        }
                    },
                    headers,
                    opts,
                    "POST"
                );
            }
        } else {
            const data = {
                username: this.state.username,
                password: this.state.password1
            };

            if (this.isValid(data)) {
                const headers = new Headers();
                headers.append("Content-Type", "application/json");
                headers.append("cache-control", "no-cache");

                const opts = {
                    username: this.state.username,
                    password: this.state.password1
                    /* "password_confirmation": this.state.password2,
                    "email" : this.state.email */
                };

                callBackend(
                    Settings.endpoints.login,
                    response => {
                        const errors = {};

                        if (response.key) {
                            const user = {};
                            user.username = this.state.username;
                            user.key = response.key;
                            user.auth = "default";

                            this.close();
                            this.logInSuccess(user);
                        } else {
                            alert(JSON.stringify(response.non_field_errors[0]));
                            errors.username = response.username;
                            errors.password1 = response.password1;
                            errors.password2 = response.password2;
                            errors.email = response.email;

                            this.setState({ errors });
                        }
                    },
                    headers,
                    opts,
                    "POST"
                );
            }
        }
    }

    onChange(e) {
        this.setState({ [e.target.id]: e.target.value, errors: "" });
    }

    close() {
        // this.setState({ showModal: false });
        this.props.close();
        // window.location.reload();
    }

    open(isSignUp) {
        this.setState({ showModal: true, isSignUp });
    }

    statusChangeCallback(response) {
        if (response.status === "connected") {
            // Logged into your app and Facebook.
            // console.log("Already logged in");
        } else if (response.status === "not_authorized") {
        }
    }

    googleLogin(e) {
        e.preventDefault();

        GoogleSignIn.SignIn().then(() => {
            const googleUser = window.gapi.auth2
                .getAuthInstance()
                .currentUser.get();
            const authResponse = googleUser.getAuthResponse(true);
            const profile = googleUser.getBasicProfile();

            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("cache-control", "no-cache");

            const body = {
                access_token: authResponse.access_token
            };

            callBackend(
                Settings.endpoints.google,
                findresponse => {
                    const user = {};
                    user.username = profile.getName();
                    user.key = findresponse.key;
                    user.auth = "google";

                    this.close();
                    this.logInSuccess(user);
                },
                headers,
                body,
                "POST"
            );
        });
    }

    facebookLogin(e) {
        e.preventDefault();

        window.FB.login(
            resp => {
                if (resp.authResponse) {
                    // //console.log('Welcome!  Fetching your information.... ');
                    window.FB.api("/me", response => {
                        // //console.log('Good to see you, ' + response.name + '.');
                        const headers = new Headers();
                        headers.append("Content-Type", "application/json");
                        headers.append("cache-control", "no-cache");
                        const body = {
                            access_token: resp.authResponse.accessToken
                        };
                        //console.log(`body.....${body}`);
                        callBackend(
                            Settings.endpoints.facebook,
                            findresponse => {
                                const user = {};
                                user.username = response.name;
                                user.key = findresponse.key;
                                user.auth = "facebook";
                                this.close();
                                this.logInSuccess(user);
                            },
                            headers,
                            body,
                            "POST"
                        );
                    });
                } else {
                    console.log(
                        "User cancelled login or did not fully authorize."
                    );
                }
            },
            { scope: "email,user_likes" }
        );
    }

    isValid(data) {
        const { errors, isValid } = validateInput(data);

        if (!isValid) {
            this.setState({ errors });
        }

        return isValid;
    }

    render() {
        const { isSignUp, errors } = this.state;

        return (
            <Modal
                dialogClassName="signup-modal"
                show={this.state.showModal}
                onHide={this.close}
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <Row>
                        <Col sm={7}>
                            <form className="form-bar" onSubmit={this.onSubmit}>
                                <FormGroup>
                                    {isSignUp ? (
                                        <h1>Sign Up</h1>
                                    ) : (
                                        <h1>Login into your account</h1>
                                    )}
                                    <FieldGroup
                                        errors={errors.username}
                                        id="username"
                                        type="text"
                                        size="md"
                                        placeholder="Enter User Name"
                                        onChange={this.onChange}
                                    />

                                    {isSignUp && (
                                        <FieldGroup
                                            errors={errors.email}
                                            id="email"
                                            type="text"
                                            size="md"
                                            placeholder="Enter Email"
                                            onChange={this.onChange}
                                        />
                                    )}

                                    <FieldGroup
                                        errors={errors.password1}
                                        id="password1"
                                        type="Password"
                                        size="md"
                                        placeholder="Enter Password"
                                        onChange={this.onChange}
                                    />

                                    {isSignUp && (
                                        <FieldGroup
                                            errors={errors.password2}
                                            id="password2"
                                            type="Password"
                                            size="md"
                                            placeholder="Re-enter Password"
                                            onChange={this.onChange}
                                        />
                                    )}

                                    <Button
                                        type="submit"
                                        className="btn btn-success btn-md"
                                        bsStyle="primary"
                                        block
                                    >
                                        {isSignUp ? "Sign Up" : "Sign In"}
                                    </Button>
                                </FormGroup>

                                {!isSignUp && (
                                    <div>
                                        <span className="checkbox">
                                            <label>
                                                <input
                                                    id="a1"
                                                    type="checkbox"
                                                    value=""
                                                />
                                                Remember me
                                            </label>
                                        </span>
                                        <span style={{ float: "right" }}>
                                            {" "}
                                            <a href="">forgot password?</a>{" "}
                                        </span>
                                    </div>
                                )}
                            </form>
                        </Col>

                        <Col className="left-padding-social" sm={5}>
                            <h1>
                                {isSignUp ? (
                                    <span>Use your social accounts</span>
                                ) : (
                                    <a href="" onClick={this.changeToSignup}>
                                        Don't have an account? Sign Up?
                                    </a>
                                )}
                            </h1>
                            <div className="buffer" />
                            <div
                                className="btn-group btn-group-justified"
                                onClick={this.facebookLogin}
                                onKeyDown={this.facebookLogin}
                                role="button"
                                tabIndex={0}
                            >
                                <div className="btn-group" role="group">
                                    <button
                                        type="button"
                                        className="fa fa-facebook btn btn-primary .btn.disabled btn-md"
                                    />
                                </div>

                                <div className="btn-group" role="group">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-md"
                                    >
                                        Login with Facebook
                                    </button>
                                </div>
                            </div>
                            <div
                                className="btn-group btn-group-justified"
                                onClick={this.googleLogin}
                                onKeyDown={this.facebookLogin}
                                role="button"
                                tabIndex={0}
                            >
                                <div className="btn-group" role="group">
                                    <button
                                        type="button"
                                        className="fa fa-google-plus btn btn-danger .btn.disabled btn-md"
                                    />
                                </div>

                                <div className="btn-group" role="group">
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-md"
                                    >
                                        Login with Google
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}

const SignUp = props => <Login isSignUp {...props} />;

const mapStateToProps = state => ({
    authentication: state.authentication
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

export const LoginDialog = connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
export const SignUpDialog = connect(
    mapStateToProps,
    mapDispatchToProps
)(SignUp);
