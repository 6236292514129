import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './CustomNavbar.css';
import {
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  Button,
  ButtonToolbar,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
import { LoginDialog, SignUpDialog } from './Login';
import * as actionCreators from '../actions';
import * as Settings from '../Config.json';
import { callBackend } from './utils';
import GoogleSignIn from './GoogleSignIn';
import FacebookSignIn from './FacebookSignIn';
import CartModel from './CartModel';
import DropDownNav from './DropDownNav';
import SearchDropdown from './SearchDropdown';
import Categories from './icons/Categories';
import logo from '../images/logo.png';

class CustomNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showRegister: false,

      isOpen: false,

      cartCount: null,

      isOpenCategories: false,
      isOpenSearch: false,
      linkToOrder: false,
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.onNavItemClick = this.onNavItemClick.bind(this);
    this.onNavSerachClick = this.onNavSerachClick.bind(this);
    this.close = this.close.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.completeLogOut = this.completeLogOut.bind(this);
    const handleToClose = this.handleToClose.bind(this);
    this.linkToOrderClick = this.linkToOrderClick.bind(this);
  }

  linkToOrderClick() {
    window.location.href = '/order';
  }

  handleToClose(someArg) {
    // alert('We pass argument from Child to Parent: ' + someArg);
    this.setState({ isOpenCategories: someArg });
    this.setState({ isOpenSearch: someArg });
  }

  componentDidMount() {
    FacebookSignIn.LoadSDK();
    GoogleSignIn.LoadSDK();
  }

  onNavItemClick() {
    const { isOpenCategories } = this.state;

    this.setState({ isOpenCategories: !isOpenCategories });
  }

  onNavSerachClick() {
    const { isOpenSearch } = this.state;

    this.setState({ isOpenSearch: !isOpenSearch });
  }

  close(props) {
    props.actions.loginReset();
    this.setState({ showRegister: false });
  }

  completeLogOut() {
    this.props.actions.logOut();
    const storedUser = localStorage.getItem('user');
    const user = JSON.parse(storedUser);

    if (user.auth === 'google') {
      window.gapi.auth2.getAuthInstance().signOut();
    } else if (user.auth === 'facebook') {
      window.FB.logout((response) => {
        // alert(response);
      });
    } else {
      // console.log("Error");
    }

    localStorage.removeItem('user');

    callBackend(Settings.endpoints.signout,
      (response) => {
        // alert(response.detail);
       // console.log(response.detail);
      },
      '',
      '',
      'POST');
    this.setState({ showRegister: false });
  }

  logIn(props) {
    props.actions.loginRequest();
    this.setState({ showRegister: false });
  }

  signUp(props) {
    props.actions.loginReset();
    this.setState({ showRegister: true });
  }

  handleSelect(selectedKey, props) {
    if (selectedKey === 1) {
      this.logIn(props);
    }
    if (selectedKey === 2) {
      this.signUp(props);
    }
    /*
        if (selectedKey === 3) {
            this.completeLogOut(props);
        } */
  }

  handleOpen() {
    this.setState({ isOpen: true });
  }

  handleClose() {
    this.setState({ isOpen: false });
  }

  render() {
    const { loggedIn, initiateLoggedIn, username } = this.props;
    const { showRegister, isOpen } = this.state;
    // this.state.cartCount = localStorage.length;
    // const cartItem = this.state.cartCount;
    const handleToClose = this.handleToClose;

    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <Navbar collapseOnSelect fixedTop style={{}}>
        <Navbar.Header style={{ height: '60px' }}>
          <Navbar.Brand className="dispalaytogether">
            <Link to="/">
              <img
                alt="Taste of Imphal"
                className="toi-logo"
                src={logo}
              />
            </Link>
            <Nav>
              <NavItem id="toggle_box"
                className="toogle-box"
                style={{ paddingLeft: '20px', top: '-15px' }}
                eventKey={1}
                onClick={this.onNavItemClick}
              >
                <Categories />
                {/* <FontAwesomeIcon icon="th" size="2x" /> */}
              </NavItem>
            </Nav>
            <Nav>
              <NavItem
                className="toogle-search"
                style={{ paddingLeft: '-35px', top: '-20px' }}
              >
                <Button
                  onClick={this.onNavSerachClick}
                  style={{ border: 'white', color: 'green' }}
                >
                  <i
                    style={{ fontSize: '20px' }}
                    className="fa fa-search"
                  />
                </Button>
              </NavItem>
            </Nav>
            <Nav>
              <NavItem
                className="cart-model-navbar-mobile"
                style={{ paddingLeft: '-30px', top: '-15px' }}
                href="#"
              >
                <CartModel />
              </NavItem>
            </Nav>
          </Navbar.Brand>

          <Navbar.Toggle />
        </Navbar.Header>

        <Navbar.Collapse>
          <Nav className="nav-collapse-in-mobile" pullRight>
            <NavItem className="cart-model-navbar" href="#">
              <CartModel />
            </NavItem>

            <NavItem
              eventKey={2}
              componentClass={Link}
              href="/"
              to="/"
            >
                            Home
            </NavItem>
            <NavItem
              eventKey={3}
              componentClass={Link}
              href="/about"
              to="/about"
            >
                            About us
            </NavItem>
            {!loggedIn ? (
              <Nav
                onSelect={key => this.handleSelect(key, this.props)
                }
                pullRight
                className="login-singup-mobile-view"
              >
                <LoginDialog
                  show={initiateLoggedIn}
                  close={() => this.close(this.props)}
                />
                <SignUpDialog
                  show={showRegister}
                  close={() => this.close(this.props)}
                />
                <NavItem eventKey={1} href="#">
                                    Log In
                </NavItem>
                <NavItem eventKey={2} href="#">
                                    Sign Up
                </NavItem>
              </Nav>
            ) : (
              <Nav pullRight className="login-singup-mobile-view">
                <NavDropdown
                  onMouseEnter={this.handleOpen}
                  onMouseLeave={this.handleClose}
                  open={this.state.isOpen}
                  title="Hello"
                  noCaret
                  id="language-switcher-container"
                >
                  <MenuItem>
                    <i className="fa fa-gear fa-fw" />
                    {' '}
                    {username}
                  </MenuItem>
                  <MenuItem onClick={this.linkToOrderClick}>
                    <i className="fa fa-credit-card" />
                    {' '}
Your
                                        Order
                  </MenuItem>
                  <MenuItem divider />
                  <MenuItem onClick={this.completeLogOut}>
                    <i className="fa fa-sign-out fa-fw" />
                    {' '}
                                        Sign Out
                  </MenuItem>
                </NavDropdown>
              </Nav>
            )}
          </Nav>
        </Navbar.Collapse>
        <DropDownNav
          show={this.state.isOpenCategories}
          handleToClose={handleToClose.bind(this)}
        />
        <SearchDropdown show={this.state.isOpenSearch}   
        handleToClose={handleToClose.bind(this)}
         />
      </Navbar>
    );
  }
}

CustomNavbar.defaultProps = {
  loggedIn: false,
  initiateLoggedIn: true,
  username: '',
};

CustomNavbar.propTypes = {
  loggedIn: PropTypes.bool,
  initiateLoggedIn: PropTypes.bool,
  username: PropTypes.string,
};

const mapStateToProps = state => ({
  loggedIn: state.authentication.loggedIn,
  initiateLoggedIn: state.authentication.initiateLoggedIn,
  username: state.authentication.user.username,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
});

export default connect(mapStateToProps,
  mapDispatchToProps)(CustomNavbar);

