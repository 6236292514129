import React, { Component, Fragment, PropTypes } from "react";

import "./RestaurantDetails.css";

import RestaurantMenuItem from "./RestaurantMenuItem";
import ReactGA from "react-ga";

class RestuarantMenu extends Component {
    constructor(props) {
        super(props);
        // this.fCat = this.fCat.bind(this);
        this.state = {
            numInput: 1,
            cart: [],
            showCartButton: false
        };
    }

    render() {
        const { items } = this.props;

        ReactGA.pageview(window.location.pathname + window.location.search);
        return (
            <Fragment>
                <thead>
                    <tr>
                        <th>{this.props.header}</th>
                        <th />
                        <th />
                        <th />
                    </tr>
                </thead>
                {items.map(element => (
                    <RestaurantMenuItem item={element} />
                ))}
            </Fragment>
        );
    }
}
RestuarantMenu.propTypes = {};

export default RestuarantMenu;
