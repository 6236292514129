import * as Settings from "../Config.json";

const GoogleSignIn = {
    LoadSDK: function() {
        return new Promise((resolve, reject) => {
            //console.log("Inside Promise");

            const updateSigninStatus = isSignedIn => {
                if (isSignedIn) {
                    //not necesary now
                    //userSignCallBack();
                } else {
                    ////console.log("not Signed in");
                }
            };

            //Loads Google API SDK
            (function(d, s, id) {
                var js,
                    fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.src =
                    "https://apis.google.com/js/platform.js?onload=onLoadCallback";
                fjs.parentNode.insertBefore(js, fjs);
            })(document, "script", "google-jssdk"); //This call is called closure -> https://www.w3schools.com/js/js_function_closures.asp

            window.onLoadCallback = function() {
                window.gapi.load("client:auth2", () => {
                    window.gapi.auth2
                        .init({
                            apiKey: Settings.google_api_key,
                            discoveryDocs: Settings.google_discovery_docs,
                            clientId: Settings.google_client_id,
                            scope: Settings.google_scopes
                        })
                        .then(function() {
                            // Listen for sign-in state changes.
                            window.gapi.auth2
                                .getAuthInstance()
                                .isSignedIn.listen(updateSigninStatus);
                            //gapi.auth2.currentUser.listen(userChanged);
                            // Handle the initial sign-in state.
                            updateSigninStatus(
                                window.gapi.auth2
                                    .getAuthInstance()
                                    .isSignedIn.get()
                            );
                            //userChanged(gapi.auth2.getAuthInstance().get)

                            resolve();
                        });
                });
            };
        });
    },
    SignIn: function() {
        return Promise.resolve(window.gapi.auth2.getAuthInstance().signIn());
    },
    SignOut: function() {
        window.gapi.auth2.getAuthInstance().signOut();
    }
};

export default GoogleSignIn;
