
 import { restConstants } from "../constants";

var storedRest = localStorage.getItem("rest");
let restItem = JSON.parse(storedRest);
const initialState = restItem;

export const rest = (state = initialState, action) => {
    switch (action.type) {
        case restConstants.ADD_REST:
            let newList = {
                restDetails: action.item
            };
            localStorage.setItem("rest", JSON.stringify(newList));
            return newList;

        case restConstants.REMOVE_REST:
            delete state[action.id];
            localStorage.setItem("rest", JSON.stringify({ ...state }));
            return { ...state };

        default:
            return state;
    }
};
