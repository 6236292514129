import { combineReducers } from "redux";
import { authentication } from "./authentication.reducers.jsx";
import { cart } from "./cart.reducers.jsx";
import { rest } from "./rest.reducers.jsx";
const reducer = combineReducers({
    authentication,
    cart,
    rest
});

export default reducer;
