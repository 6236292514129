import isEmpty from "lodash.isempty";
import React, { Component } from "react";
import Validator from "validator";
import {
    FormGroup,
    ControlLabel,
    FormControl,
    HelpBlock
} from "react-bootstrap";
import * as Settings from "../Config.json";

export const FieldGroup = ({
    id,
    value,
    label,
    help,
    errors,
    type,
    size,
    placeholder,
    onChange,
    checkUserExists
}) => {
    //{help && <HelpBlock>{help}</HelpBlock>}
    return (
        <FormGroup controlId={id} validationState={errors ? "error" : null}>
            {label && <ControlLabel>{label}</ControlLabel>}
            <FormControl
                type={type}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
            />
            {errors && <HelpBlock>{errors}</HelpBlock>}
        </FormGroup>
    );
};

export const validateInput = data => {
    let errors = {};

    if (
        typeof data.username != "undefined" &&
        Validator.isEmpty(data.username)
    ) {
        errors.username = "This field is required";
    }
    if (typeof data.email != "undefined" && Validator.isEmpty(data.email)) {
        errors.email = "This field is required";
    }
    if (typeof data.email != "undefined" && !Validator.isEmail(data.email)) {
        errors.email = "Email is invalid";
    }
    if (
        typeof data.password1 != "undefined" &&
        Validator.isEmpty(data.password1)
    ) {
        errors.password1 = "This field is required";
    }
    if (
        typeof data.password2 != "undefined" &&
        Validator.isEmpty(data.password2)
    ) {
        errors.password2 = "This field is required";
    }
    if (
        typeof data.password2 != "undefined" &&
        !Validator.equals(data.password1, data.password2)
    ) {
        errors.password2 = "Passwords must match";
    }

    return {
        errors,
        isValid: isEmpty(errors)
    };
};

export const callBackend = (
    url,
    responseFunc,
    headers,
    body,
    method,
    errorFunc,
    jsontype = true
) => {
    if (isEmpty(errorFunc)) {
        errorFunc = error => {
            //alert("Please retry again");
            console.log("ERROR...."+JSON.stringify(error))
        };
    }

    //
    //&& !headers.has("Authorization")
    if (!headers) {
        headers = new Headers();
        headers.append("Access-Control-Allow-Origin", "*");

        headers.append("Content-Type", "application/json");
        headers.append(
            "Api-Key",
            process.env.NODE_ENV === "development"
                ? Settings.local_api_key
                : Settings.production_api_key
        );
        headers.append("cache-control", "no-cache");
    }

    if (isEmpty(method)) {
        method = "GET";
    }

    let myInit = {};
    if (!isEmpty(body)) {
        myInit = {
            method: method,
            headers: headers,
            body: JSON.stringify(body),
            mode: "cors",
            cache: "default"
        };
    } else {
        myInit = {
            method: method,
            headers: headers,
            mode: "cors",
            cache: "default"
        };
    }

    const baseurl =
        process.env.NODE_ENV === "development"
            ? "https://api-v3.tasteofimphal.com"
            : Settings.baseurl;

    let myRequest = new Request(
        url.includes("https") ? url : baseurl + url,
        myInit
    );
    fetch(myRequest)
        .then(response => {
            return response.text();
        })
        .then(Resp => {
            !!jsontype ? responseFunc(JSON.parse(Resp)) : responseFunc(Resp);
        })
        .catch(error => errorFunc(error));
};
