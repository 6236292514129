import React, { Component } from "react";
import { Grid, Row, Col, Table, Button } from "react-bootstrap";
import "./RestaurantDetails.css";
import "./otpverify.css";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { callBackend } from "./utils";
import * as Settings from "../Config.json";
import ReactGA from "react-ga";
import Loader from 'react-loader-spinner';
import axios from 'axios';
class OtpVerify extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            otpvalue: "",
            redirect: false,
            redirectLink: "",
            spinner: false,
        };
        this.otpsverify = this.otpsverify.bind(this);
        this.setotpchange = this.setotpchange.bind(this);
    }
    setotpchange(evt) {
        this.setState({
            otpvalue: evt.target.value
        });
    }

    otpsverify() {
        window.scrollTo(0, 0)
        let otpskey = localStorage.getItem("otpkey");
        let otp = this.state.otpvalue;
        this.setState({
            spinner: true
        })
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("cache-control", "no-cache");
        let data = {
            otp: otp,
            ids: otpskey
        };

        //get login user information
        var user = JSON.parse(localStorage.getItem("user"));

        //var user_key = user.key;
        //var user_login_name = user.username;
        var address = localStorage.getItem("addressDetails");
        var cartItems = localStorage.getItem("cartItemss");
        var totalPrice = localStorage.getItem("totalPrice");
        var paymode = localStorage.getItem("paymentMode");
        var addressphone = JSON.parse(localStorage.getItem("addressInfo"));
        let datapostChecking = {
            total_price: totalPrice,
            cart_items: JSON.parse(cartItems)

        };
       // console.log("address "+JSON.stringify(address));
        let datapost = {
            total_price: totalPrice,
            address: address,
            card_items: cartItems,
            payment_mode: paymode,
            user_key: user.key,
            request_id: "",
            success_id: "",
            status: false
        };

        callBackend(

            "/verify_otp/",
            response => {
                if (response.Status == "Success") {
                    if (typeof window !== "undefined") {
                        // window.location.href = window.location.origin
                        alert("Verified");
                        this.setState({
                            spinner: true
                        })
                        /*
                        //Remove WebCheck due to issue arise in backend calculation mismatch(Backend delivery charge takes from area base only)
                        axios.post(Settings.baseurl +'/webcheck/', datapostChecking, headers)
                            .then((vresponse) => {
                                console.log("?????????????"+JSON.stringify(vresponse));
                                //alert(resp11.data.message);
                                if (vresponse.data.status === "200") {*/
                                    axios.post(Settings.baseurl +'/paymentsubmit/', datapost, headers)
                                        .then((resp11) => {
                                            this.setState({
                                                spinner: false
                                            })
                                            alert(resp11.data.message);
                                            if (typeof window !== "undefined") {
                                                //window.location.href = window.location.origin

                                                localStorage.removeItem("otpkey");
                                                localStorage.removeItem("item");

                                                window.location.href =
                                                    window.location.origin;
                                                this.setState({
                                                    spinner: false
                                                })
                                            }

                                        })
                                        .catch((error) => {

                                            // console.log("inside catch "+ JSON.stringify(error))
                                            this.setState({
                                                spinner: false
                                            })
                                        })
                              /*  } else {
                                    this.setState({
                                        spinner: false
                                    })
                                    alert(vresponse.data.message);
                                }




                            })
                            .catch((error) => {
                                alert("Error:123 "+JSON.stringify(error));
                                this.setState({
                                    spinner: false
                                })
                            })*/



                    }
                } else {
                    alert("Enter the valid OTP");
                    this.setState({
                        spinner: false
                    })
                }
            },
            headers,
            data,
            "POST"
        );
    }
    render() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        if (this.state.spinner) {
            return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Loader
                    type="Oval"
                    color="#00BFFF"
                    height="150"
                    width="150"
                /><br/><h3 style={{ marginTop: 6 }}>Please wait...</h3></div>)
        } else {
            return (
                <div>
                    <Grid
                        style={{
                            height: "630px",
                            marginTop: " 70px",
                            paddingBottom: " 100px",
                            backgroundColor: "#F6F6F6  ",
                            textAlign: "center",
                            paddingTop: "80px"
                        }}
                    >
                        <h3>OTP Verification</h3>
                        <form>
                            <input
                                className="otpinput"
                                value={this.state.otpvalue}
                                onChange={this.setotpchange}
                                placeholder="Enter OTP "
                                type="number"
                                name="otp"
                                required
                            />
                            <br />
                            <br />
                            <Button
                                style={{
                                    width: "80px",
                                    backgroundColor: "green",
                                    color: "white"
                                }}
                                onClick={this.otpsverify}
                            >
                                Submit
                        </Button>
                        </form>
                    </Grid>
                </div>
            );
        }
    }
}

export default OtpVerify;