import React, { Component } from "react";
import { Grid, Row, Col, Thumbnail, Image } from "react-bootstrap";
import "./HomeRestaurantList.css";
import Slider from "react-slick";
// import PropTypes from 'prop-types';
import { callBackend } from "./utils";
import * as Settings from "../Config.json";
import { Link } from "react-router-dom";

import ReactLoading from "react-loading";

const Loader = ({ type, color }) => (
    <ReactLoading type="bars" color="#a79b9b" height={"5%"} width={"5%"} />
);

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className="fa fa-angle-right"
            style={{
                display: "block",
                background: "rgba(232, 228, 228, 0.25)",
                right: "18px",
                color: "#c3bfbf",
                position: "absolute",
                top: "120px",
                height: "40px",
                width: "30px",
                paddingTop: "7px",
                fontSize: "28px",
                borderBottomLeftRadius: "5px",
                borderTopLeftRadius: "5px"
            }}
            onClick={onClick}
            onKeyDown={onClick}
            onClick={onClick}
            onKeyDown={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className="fa fa-angle-left"
            style={{
                display: "block",
                background: "rgba(232, 228, 228, 0.25)",
                left: "18px",
                color: "#c3bfbf",
                position: "absolute",
                zIndex: "1",
                top: "120px",
                height: "40px",
                width: "30px",
                paddingTop: "7px",
                fontSize: "28px",
                borderBottomRightRadius: "5px",
                borderTopRightRadius: "5px"
            }}
            onClick={onClick}
            onKeyDown={onClick}
        />
    );
}

export default class HomeRestaurantList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            restaurantList: [],
            restaurantImage: "",
            restaurantId: 0,
            loading: true
        };
        // this.onclicktoRestaurant = this.onclicktoRestaurant.bind(this);
    }

    componentDidMount() {
      /*  const header = new Headers();
        callBackend(
            Settings.baseurl + "/api/priority_rest/",
            response => {
                const restaurantList = response;
                this.setState({ restaurantList });
                this.setState({ loading: false });
            },
            header
        );*/

        fetch(Settings.baseurl +"/api/priority_rest/")
        .then(Response => Response.json())
        .then(findresponse => {
            const restaurantList = findresponse;
                this.setState({ restaurantList });
                this.setState({ loading: false });
           
        });
    }

    // onclicktoRestaurant(selectedKey, props) {}

    render() {
        const settings = {
            dots: false,
            infinite: this.state.restaurantList.length > 3,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: this.state.restaurantList.length > 3,
                        dots: false,
                        nextArrow: <SampleNextArrow />,
                        prevArrow: <SamplePrevArrow />
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2,
                        nextArrow: <SampleNextArrow />,
                        prevArrow: <SamplePrevArrow />
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1.05,
                        slidesToScroll: 1,
                        nextArrow: false,
                        prevArrow: false
                    }
                }
            ]
        };

        const { loading } = this.state;

        return (
            <Grid
                style={{
                    textAlign: "center",
                    marginTop: "20px",
                    padding: "0px",
                    marginBottom: "100px"
                }}
            >
                <h3 id="headings">
                    <span className="underline">
                        Restaurants in and around Imphal
                    </span>
                </h3>

                {loading ? (
                    <div className="loader">
                        <Loader />
                    </div>
                ) : (
                    <Row className="show-grid text-center">
                        <Slider {...settings}>
                            {this.state.restaurantList.map((pic, key) => {
                                const str = pic.photo;
                                var a = str;
                                var b = "s";
                                var position = 4;
                                var output = [
                                    a.slice(0, position),
                                    b,
                                    a.slice(position)
                                ].join("");
                                var currentTime = new Date();
                                var hrs = ('0'+currentTime.getHours()).substr(-2);
                                var t = hrs + ":" + currentTime.getMinutes();
                               // var t =currentTime.getHours()  + ":" + currentTime.getMinutes();
                                var closeFlag = true
                               // console.log("flag "+pic.open_time)
                                if(t >= pic.open_time && t <= pic.close_time){
                                  closeFlag = false
                                }
                                
                                return (
                                    <Col
                                        xs={12}
                                        sm={4}
                                        className="col-home-rest-list"
                                        key={key}
                                    >
                                         
                                        <Link to={`/restaurant/${pic.id}`}>
                                        { (closeFlag === true || pic.closed) && <p className="top-left-closed">CLOSED</p>}
                                            <img src={str} className="f-img" />
                                        </Link>
                                    </Col>
                                );
                            })}
                        </Slider>
                    </Row>
                )}
            </Grid>
        );
    }
}
