import { userConstants } from "../constants";

var stored_user = localStorage.getItem("user");
let user = JSON.parse(stored_user);
const initialState = user
    ? { initiateLoggedIn: false, loggedIn: true, user }
    : { initiateLoggedIn: false, loggedIn: false, user: {} };

export const authentication = (state = initialState, action) => {
    switch (action.type) {
        case userConstants.LOGIN_SUCCESS:
            return {
                initiateLoggedIn: false,
                loggedIn: true,
                user: action.user
            };
        case userConstants.LOGIN_REQUEST:
            return {
                initiateLoggedIn: true,
                loggedIn: false,
                user: {}
            };

        case userConstants.LOGIN_RESET:
            return {
                initiateLoggedIn: false,
                loggedIn: false,
                user: {}
            };

        case userConstants.LOGOUT:
            return {
                initiateLoggedIn: false,
                loggedIn: false,
                user: {}
            };

        default:
            return state;
    }
};
