import React, { Component } from "react";
import { Grid, Col, Image } from "react-bootstrap";
import "./About.css";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { callBackend } from "./utils";
import ReactLoading from "react-loading";

const Loader = ({ type, color }) => (
    <ReactLoading type="bars" color="#a79b9b" height={"5%"} width={"5%"} />
);
class OrderHistory extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: [],
            food_items: []
        };
    }

    componentDidMount() {
        var user = JSON.parse(localStorage.getItem("user"));
        let data = {
            user_key: user.key
        };
        /* this.setState({ hi: this.props.match.params.resId });*/
        // var id = this.props.match.params.id
        const header = new Headers();

        header.append("Content-Type", "application/json");
        header.append("cache-control", "no-cache");
        callBackend(
            "/order_list/",
            response => {
                this.setState({ order: response });
              //  console.log("<<<<<<<<<" + JSON.stringify(response));
            },
            header,
            data,
            "POST"
        );
    }
    render() {
        const { loggedIn } = this.props.authentication;
        if (!loggedIn) {
            return <Redirect to="/" />;
        }
       // console.log(">>123>"+JSON.stringify(this.state.order))
        if (this.state.order.length === 0) {
            return (
                <div className="about-root-div">
                    <Grid>
                        <Col xs={12} sm={8} smOffset={2}>
                            <h1 className="about-h1">No order has been made</h1>
                        </Col>
                    </Grid>
                </div>
            );
        } else {
            return (
                <div className="about-root-div">
                    <Grid>
                        <Col xs={12} sm={8} smOffset={2}>
                            <h1 className="about-h1">Your Order</h1>
                            <div className="order-container">
                                {this.state.order
                                    .reverse()
                                    .map(element => {
                                        let foodITEMS = JSON.parse(
                                            element.card_items
                                        );
                                        var d = new Date(element.payment_date);
                                       // var rdate = d.toUTCString();
                                        //var revdate = rdate.slice(4, 16)
                                       // var d = new Date(item.payment_date);
                                       var theDate = new Date( Date.parse(element.payment_date));
                                        var rdate = d.toUTCString();
                                        var revdate = rdate.slice(4, 16)    
                                        //const fooditems = (props) => { return JSON.parse (element.card_items)};
                                        return (
                                            <Col>
                                                
                                            
                                                    {element.order_no.length !== 0 && <b>  Order No: {element.order_no[0].order_track_no} </b>}
                                                
                                                <p>Order Date: {revdate}   {'\t'}     {theDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' ,hour12: true})}</p>
                                                <ul>
                                                    {" "}
                                                    Details:-
                                                    {foodITEMS.foods.map(
                                                        res => (
                                                            <li>
                                                                {res.food_name}{" "}
                                                                &nbsp;{" "}
                                                                {" Price: " +
                                                                    res.price}{" "}
                                                                &nbsp;{" "}
                                                                {" Quantity: " +
                                                                    res.quantity}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                                <p>
                                                    Payment mode:{" "}
                                                    {element.payment_mode}{" "}
                                                </p>
                                                <b>
                                                    Total Amount: ₹{" "}
                                                    {element.total_price}
                                                </b>
                                                <hr />
                                            </Col>
                                        );
                                    })}
                            </div>
                        </Col>
                    </Grid>
                </div>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        authentication: state.authentication
    };
};
export default connect(mapStateToProps)(OrderHistory);
