import React, { Component } from "react";
import { Grid, Row, Col, Table, Button } from "react-bootstrap";
import "./RestaurantDetails.css";
import "./BillingForm.css";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { callBackend } from "./utils";
import * as Settings from "../Config.json";
import ReactGA from "react-ga";
import ReactAutocomplete from "react-autocomplete";
import Loader from "react-loader-spinner";
import axios from 'axios';
import Countdown from 'react-countdown-now';
function calculateTotal(items) {
    let total = 0;
    items !== null &&
        Object.keys(items).forEach(key => (total += items[key].quantity));
    return total;
}

class BillingForm extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.radioget = this.radioget.bind(this);
        this.state = {
            paytype: "online",
            //value: "",
            redirect: false,
            redirectLink: "",
            areas: [],
            value: "",
            spinner: false,
            dprice: this.props.sameDeliveryFlag ? this.props.sameDeliveryCharge : (this.props.restDetails.restDetails.gst_number !== "" ? this.props.restDetails.restDetails.gst_number : ""),
            offerData: [],
            spinnerRazor: false,
            areaid: "",
            checkVersionTime: [],
            sameDeliveryFlag: false,
            sameDeliveryCharge: "",
            min_amount: "",
            from_time: "",
            upto_time: ""
        };


    }



    componentDidMount() {
        const header = new Headers();
        this.setState({ spinner: true });
        callBackend(
            "/api/area/",
            response => {
                this.setState({ areas: response });
                //window.scrollTo(0, 0)

                callBackend(
                    "/api/deliveryoffer/",
                    response1 => {
                        console.log("offer data ... " + JSON.stringify(response1));
                        console.log("min amount " + JSON.stringify(response1))
                        this.setState({

                            offerData: response1,
                            min_amount: response1[0]["min_amout"],
                            from_time: response1[0]["time_from"],
                            upto_time: response1[0]["time_upto"],
                            spinner: false,
                            sameDeliveryFlag: response1[0]["delivery_charge_flag"],
                            sameDeliveryCharge: response1[0]["delivery_charge"]
                        });
                        window.scrollTo(0, 0);

                        fetch(Settings.baseurl + "/api/version_check/")
                            .then(Response => Response.json())
                            .then(findresponse => {
                                this.setState({
                                    checkVersionTime: findresponse,
                                });
                            });
                    },
                    header
                );
            },
            header
        );
    }



    getValidationState() {
        const length = this.state.value.length;
        if (length > 10) return "success";
        if (length > 5) return "warning";
        if (length > 0) return "error";
        return null;
    }
    radioget() { }

    payvaluechange(event) {
        this.setState({
            paytype: event.target.value
        });
    }

    handleChange(e) {
        this.setState({ value: e.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            spinner: true
        });
        if (this.state.value === "") {
            alert("Please select your area");
            this.setState({
                spinner: false
            });
        } else {
            var flag = false;
            this.state.areas.map(i => {
                if (i.area === this.state.value) {
                    //alert("PRICE : "+i.price)
                    flag = true;

                }
            });

            if (flag) {
                if (this.state.dprice === "") {
                    alert("Please select the valid area from the options");
                    this.setState({
                        spinner: false
                    });
                } else {


                    const formData = new FormData(event.target);

                    var currentTime = new Date();
                    var t =
                        currentTime.getHours() + ":" + currentTime.getMinutes();

                    var ConvertedJSON = {};
                    for (const [key, value] of formData.entries()) {
                        ConvertedJSON[key] = value;
                    }
                    var addressInformation = JSON.stringify(ConvertedJSON);
                    console.log("address " + JSON.stringify(addressInformation));
                    localStorage.setItem("addressInfo", addressInformation);

                    localStorage.setItem("paymentMode", this.state.paytype);
                    //get login user information
                    var user = JSON.parse(localStorage.getItem("user"));
                    var user_key = user.key;
                    var user_login_name = user.username;

                    //set data for payment
                    const { cartItems } = this.props;
                    var jsonArr = [];
                    var paymentTotalPrice = 0;
                    var deliveryCharge = this.state.dprice;
                    var numOfRest = 0;
                    var a = [];
                    var paymentTotalAmount = 0;
                    var paymentTotalAmount1 = 0;
                    var totalPackCharge = 0;
                    var totalQuantityPackCharge = 0;
                    var gst_percent_total = 0;
                    Object.keys(cartItems).map(key => {
                        a.push(cartItems[key].restaurant);
                        //set data for payment
                        paymentTotalPrice =
                            paymentTotalPrice +
                            cartItems[key].price * cartItems[key].quantity;
                        var uniques = a.filter(function (item, i, ar) {
                            return ar.indexOf(item) === i;
                        });
                        /*
                        if (uniques.length > 2) {
                            deliveryCharge = uniques.length * 10 + 10;
                        } else {
                            deliveryCharge = this.state.dprice;
                        }*/
                        if (uniques.length > 1) {
                            deliveryCharge =
                                uniques.length * 10 -
                                10 +
                                parseInt(this.state.dprice);
                        } else {
                            deliveryCharge = parseInt(this.state.dprice);
                        }
                        numOfRest = uniques.length;
                        if (cartItems[key].packing_charge) {
                            totalPackCharge =
                                totalPackCharge +
                                cartItems[key].packing_charge *
                                cartItems[key].quantity;
                            totalQuantityPackCharge =
                                totalQuantityPackCharge +
                                cartItems[key].quantity;
                        }
                        if (cartItems[key].gst_value) {
                            //gst_percent_total = gst_percent_total + (cartItems[key].gst_value*(cartItems[key].quantity*cartItems[key].price))/100;
                            gst_percent_total =
                                gst_percent_total +
                                (cartItems[key].gst_value *
                                    (cartItems[key].quantity *
                                        cartItems[key].price +
                                        cartItems[key].quantity *
                                        cartItems[key].packing_charge)) /
                                100;
                        }
                        jsonArr.push(cartItems[key]);
                    });

                    //var DeliveryCharge = '0';
                    paymentTotalAmount = Math.ceil(
                        totalPackCharge +
                        paymentTotalPrice +
                        gst_percent_total
                    );
                    parseInt(Math.ceil(paymentTotalAmount)) >
                        parseInt(this.state.offerData[0]["min_amout"])
                        ? (paymentTotalAmount = Math.round(
                            totalPackCharge +
                            paymentTotalPrice +
                            gst_percent_total
                        ))
                        : ((t >= this.state.offerData[0]["time_from"] &&
                            t <= this.state.offerData[0]["time_upto"])
                            ? (paymentTotalAmount = Math.round(
                                totalPackCharge +
                                paymentTotalPrice +
                                gst_percent_total
                            ))
                            : (paymentTotalAmount = Math.round(
                                totalPackCharge +
                                parseInt(deliveryCharge) +
                                paymentTotalPrice +
                                gst_percent_total
                            )));





                    var cartItemss = JSON.stringify(jsonArr);

                    paymentTotalAmount1 = Math.ceil(
                        totalPackCharge +
                        paymentTotalPrice +
                        gst_percent_total
                    );
                    var platform = "Website";
                    parseInt(Math.ceil(paymentTotalAmount1)) >
                        parseInt(this.state.offerData[0]["min_amout"])
                        ? (localStorage.setItem(
                            "cartItemss",
                            '{"foods":' +
                            cartItemss +
                            ', "total":{"delivery_charge":' +
                            '0' +
                            ', "total_restuarant":' +
                            numOfRest +
                            ', "packing_charge":' +
                            totalPackCharge +
                            ', "total_pack_item":' +
                            totalQuantityPackCharge +
                            ', "gst":' +
                            gst_percent_total +
                            ', "platform":' +
                            JSON.stringify(platform) +
                            ', "areaid":' +
                            this.state.areaid +
                            "}}"
                        ))
                        : ((t >= this.state.offerData[0]["time_from"] &&
                            t <= this.state.offerData[0]["time_upto"])
                            ? (localStorage.setItem(
                                "cartItemss",
                                '{"foods":' +
                                cartItemss +
                                ', "total":{"delivery_charge":' +
                                '0' +
                                ', "total_restuarant":' +
                                numOfRest +
                                ', "packing_charge":' +
                                totalPackCharge +
                                ', "total_pack_item":' +
                                totalQuantityPackCharge +
                                ', "gst":' +
                                gst_percent_total +
                                ', "platform":' +
                                JSON.stringify(platform) +
                                ', "areaid":' +
                                this.state.areaid +
                                "}}"
                            ))
                            : (localStorage.setItem(
                                "cartItemss",
                                '{"foods":' +
                                cartItemss +
                                ', "total":{"delivery_charge":' +
                                parseInt(deliveryCharge) +
                                ', "total_restuarant":' +
                                numOfRest +
                                ', "packing_charge":' +
                                totalPackCharge +
                                ', "total_pack_item":' +
                                totalQuantityPackCharge +
                                ', "gst":' +
                                gst_percent_total +
                                ', "platform":' +
                                JSON.stringify(platform) +
                                ', "areaid":' +
                                this.state.areaid +
                                ', "delivery_time": "Now"' +
                                ', "delivery_type": "Now"' +
                                "}}"
                            )));

                    //"total\": {\"delivery_charge\": 40, \"total_restuarant\": \"\", \"packing_charge\": 0, \"total_pack_item\": 0, \"gst\": 0}}
                    localStorage.setItem("totalPrice", paymentTotalAmount);

                    /*--------------*/
                    var razorEmail = ConvertedJSON.email;
                    var pAddress = {};
                    pAddress = ConvertedJSON;
                    var key = "email";
                    delete pAddress[key];

                    pAddress = { ...pAddress, area: this.state.value, area_price: this.state.dprice };
                    localStorage.setItem("addressDetails", JSON.stringify(pAddress));

                    if (this.state.paytype === "online") {


                        window.scrollTo(0, 0)
                        let headers = new Headers();
                        headers.append("Content-Type", "application/json");
                        headers.append("cache-control", "no-cache");

                        this.setState({
                            spinner: true
                        });
                        var cartItemsWebCheck = localStorage.getItem("cartItemss");
                        var cartItemsssWeb = JSON.parse(cartItemsWebCheck);
                        //var cartData = {cart_items:{cartItemsss}}
                        /*
                        let datapostChecking = {
                            total_price: parseInt(Math.ceil(paymentTotalAmount)),
                            cart_items: cartItemsssWeb

                        };


                        axios.post(Settings.baseurl + '/webcheck/', datapostChecking, headers)
                            .then((vresponse) => {
                                
                                if (vresponse.data.status === "200") {*/
                        //  rzp_test_gY8qBe1b0Mu0bw
                        let options = {
                            currency: 'INR',
                            key: "rzp_live_KAkK467khfFpYY",
                            amount: parseInt(Math.ceil(paymentTotalAmount) + '00'), // 2000 paise = INR 20, amount in paisa
                            name: "Food Orders",
                            description: "Credits towards Taste of Imphal",
                            image: "https://i.imgur.com/lunyZwU.png",
                            prefill: {
                                name: user.username,
                                email: razorEmail,
                                contact: ConvertedJSON.phone
                            },
                            notes: { total_price: parseInt(paymentTotalAmount), payment_mode: 'online', user_key: user.key, status: true },
                            theme: {
                                "color": "#F37254"
                            },

                            handler: (response) => {
                                // console.log("Razor response >>"+JSON.stringify(response));
                                var cartItems1 = localStorage.getItem("cartItemss");
                                var cartItemsss = JSON.parse(cartItems1);
                                //var cartData = {cart_items:{cartItemsss}}

                                const cartData = {
                                    cart_items: cartItemsss,
                                    address: pAddress,
                                    request_id: response.razorpay_payment_id,
                                    user_id: user.key
                                }



                                this.setState({ spinner: true, spinnerRazor: true });


                                axios.post(Settings.baseurl + "/prepayment/", cartData, headers)
                                    .then((resp11) => {

                                        // console.log("inside "+ JSON.stringify(resp11))
                                        // alert(response.message);
                                        if (resp11.data.status === "success") {

                                            localStorage.removeItem("item");
                                            alert("Thank you for your order! We will contact you soon.");
                                            this.setState({ spinner: false, spinnerRazor: false });
                                            window.location.href = window.location.origin;
                                        }

                                    })
                                    .catch((error) => {
                                        alert("Something Wrong! Please try later");
                                        //  console.log("inside catch "+ JSON.stringify(error))
                                        this.setState({
                                            spinner: false,
                                            spinnerRazor: false
                                        })
                                        window.location.href = window.location.origin;
                                    })



                            },
                            modal: {
                                "ondismiss": () => { alert("You cancel the transaction."); this.setState({ spinner: false, spinnerRazor: false }); }
                            }
                        };
                        let rzp = new window.Razorpay(options);
                        rzp.open();
                        if (typeof window.Razorpay !== 'undefined') {
                            var ok = window.Razorpay(options);
                            ok.open();
                        }

                        /*  
                                 } else {
                                     this.setState({
                                         spinner: false
                                     })
                                     alert("ERROR:- "+vresponse.data.message);
                                 }



})
                             .catch((error) => {
                                 alert("Error: " + JSON.stringify(error));
                                 this.setState({
                                     spinner: false
                                 })
                             })*/
                        //Assigning delivery charge------------------------------------------parseInt(this.state.dprice)


                    } else {
                        window.scrollTo(0, 0)
                        var phoneNumber = localStorage.getItem(
                            "addressInfo",
                            addressInformation
                        );
                        var obj = JSON.parse(phoneNumber);
                        this.setState({
                            spinner: true
                        });
                        const phone = obj["phone"];
                        const header = new Headers();
                        // localStorage.setItem("addressDetails", pAddress);
                        callBackend(
                            "/send_otp/" + phone + "/",
                            response => {
                                localStorage.setItem(
                                    "otpkey",
                                    response.session
                                );

                                window.location.href = "/otpverify";
                                this.setState({
                                    spinner: false
                                });
                            },
                            header
                        );
                    }
                }
            } else {
                alert("Please select the valid area from the options");
                this.setState({
                    spinner: false
                });
            }
        }
    }

    render() {
        var currentTime = new Date();
        var t = currentTime.getHours() + ":" + currentTime.getMinutes();

        const { cartItems } = this.props;
        const { loggedIn } = this.props.authentication;
        const { redirect, redirectLink, isLogin } = this.state;
        var totalQuant = 0;
        var totalPrice = 0;
        var checkCart = 0;
        cartItems !== null &&
            Object.keys(cartItems).map(key => {
                totalQuant = totalQuant + cartItems[key].quantity;
                checkCart++;
            });

        /*
   if(redirect){
    return <Redirect to="/" />
   }*/
        //   console.log("test offer data "+ JSON.stringify(this.state.offerData))
        if (checkCart === 0) {
            return <Redirect to="/" />;
        }
        var a = [];
        var deliveryCharge = 0;
        this.state.dprice === ""
            ? (deliveryCharge = 0)
            : (deliveryCharge = parseInt(this.state.dprice));
        var totalPackCharge = 0;
        var totalQuantityPackCharge = 0;

        if (!loggedIn) {
            return <Redirect to="/" />;
        }

        ReactGA.pageview(window.location.pathname + window.location.search);
        var gst_percent_total = 0;
        if (this.state.spinner) {
            return (
                <div
                    style={{
                        display: "flex",

                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh"
                    }}
                >
                    <Loader
                        type="Oval"
                        color="orange"
                        height="120"
                        width="120"
                    />
                    {this.state.spinnerRazor && <div><Countdown date={Date.now() + 10000} /><h3 style={{ marginTop: 6 }}>Please wait...</h3></div>}
                </div>
            );
        } else {
            return (
                <Grid
                    style={{
                        marginTop: " 80px",
                        paddingBottom: " 100px",
                        backgroundColor: "#F6F6F6  "
                    }}
                >
                    <Row>
                        <Col md={8} sm={8} xs={12}>
                            <h3>Billing Address</h3>
                            <hr />

                            <form
                                className="billing"
                                onSubmit={this.handleSubmit}
                                style={{ position: "" }}
                            >
                                <div
                                    style={{ zIndex: 2, position: "relative" }}
                                >
                                    <h4>
                                        Type your area and select{" "}
                                        <span
                                            style={{
                                                color: "green",
                                                fontSize: "13px"
                                            }}
                                        >
                                            e.g Singjamei, Khurai, Thangmeiband
                                        </span>
                                    </h4>
                                    <h5>
                                        <span
                                            style={{
                                                color: "green",
                                                fontSize: "13px",
                                                marginLeft: 2
                                            }}
                                        >
                                            (For Delivery Charge)
                                        </span>
                                    </h5>
                                    <ReactAutocomplete
                                        items={this.state.areas}
                                        shouldItemRender={(item, value) =>
                                            item.area
                                                .toLowerCase()
                                                .indexOf(value.toLowerCase()) >
                                            -1
                                        }
                                        getItemValue={item => item.area}
                                        inputProps={{
                                            placeholder: "Type your Area"
                                        }}
                                        menuStyle={{ position: "initial" }}
                                        renderItem={(item, highlighted) => (
                                            <div
                                                key={item.id}
                                                style={{
                                                    backgroundColor: highlighted
                                                        ? "#eee"
                                                        : "transparent",
                                                    marginTop: 2
                                                }}
                                            >
                                                <h5
                                                    style={{
                                                        marginLeft: 3,
                                                        marginTop: 1
                                                    }}
                                                >
                                                    {item.area}
                                                </h5>
                                            </div>
                                        )}
                                        value={this.state.value}
                                        onChange={e =>
                                            this.setState({
                                                value: e.target.value,
                                                dprice: ""
                                            })
                                        }
                                        onSelect={(value, item) =>
                                            this.setState({
                                                value: value,
                                                dprice: this.props.sameDeliveryFlag ? this.props.sameDeliveryCharge : (this.props.restDetails.restDetails.gst_number !== "" ? this.props.restDetails.restDetails.gst_number : item.price),
                                                areaid: item.id
                                            })
                                        }
                                    />
                                </div>
                                <br />
                                <Row className="billing">
                                    <Col md={6}>
                                        <h4> First Name</h4>

                                        <input
                                            type="text"
                                            placeholder="firstname"
                                            name="firstname"
                                            required
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <h4> Last Name</h4>

                                        <input
                                            type="text"
                                            placeholder="lastname"
                                            name="lastname"
                                            required
                                        />
                                    </Col>
                                </Row>
                                <h4> Email</h4>
                                <input
                                    placeholder="uname@example.com"
                                    type="text"
                                    name="email"
                                    required
                                />
                                <br />
                                <br />
                                <h4>
                                    {" "}
                                    Phone Number{" "}
                                    <span
                                        style={{
                                            color: "green",
                                            fontSize: "14px"
                                        }}
                                    >
                                        **This Number will use for OTP
                                        verification
                                    </span>
                                </h4>
                                <input
                                    type="tel"
                                    id="phone"
                                    placeholder="+91-Phone Number"
                                    pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                                    name="phone"
                                    required
                                />
                                <br />
                                <br />

                                <h4> Address</h4>
                                <input
                                    type="text"
                                    placeholder="address"
                                    name="address"
                                    required
                                />

                                <br />
                                <br />
                                <h4>Landmark</h4>
                                <input
                                    type="text"
                                    placeholder="Landmark"
                                    name="landmark"
                                    required
                                />
                                <br />

                                <br />
                                <Row>
                                    <Col md={4}>
                                        <h4> Country</h4>
                                        <select>
                                            <option>India</option>
                                        </select>
                                    </Col>
                                    <Col md={4}>
                                        <h4> State</h4>
                                        <select>
                                            <option>Manipur</option>
                                        </select>
                                    </Col>
                                    <Col md={4}>
                                        <h4> Pin Code</h4>
                                        <input
                                            type="text"
                                            name="pincode"
                                            placeholder="795-000"
                                            required
                                        />
                                    </Col>
                                </Row>
                                <br />
                                <br />
                                <div className="radiobutton">
                                    <h4>Payment Method</h4>
                                    <input
                                        type="radio"
                                        checked={
                                            this.state.paytype === "online"
                                        }
                                        onChange={this.payvaluechange.bind(
                                            this
                                        )}
                                        name="paygroup"
                                        value="online"
                                        id="red"
                                    />
                                    Online Payment
                                    <input
                                        type="radio"
                                        checked={this.state.paytype === "cod"}
                                        name="paygroup"
                                        onChange={this.payvaluechange.bind(
                                            this
                                        )}
                                        value="cod"
                                    />
                                        Cash On delivery
                                </div>
                                <br />
                                <br />
                                <Button
                                    style={{
                                        width: "80px",
                                        backgroundColor: "green",
                                        color: "white"
                                    }}
                                    type="submit"
                                    value="Checkout"
                                >
                                    Submit
                                </Button>
                            </form>

                        </Col>
                        <Col
                            md={4}
                            sm={4}
                            xs={12}
                            style={{
                                marginTop: " 20px"
                            }}
                        >
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Items</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cartItems &&
                                        Object.keys(cartItems).map(key => {
                                            a.push(cartItems[key].restaurant);

                                            totalQuantityPackCharge =
                                                totalQuantityPackCharge +
                                                cartItems[key].quantity;
                                            var uniques = a.filter(function (
                                                item,
                                                i,
                                                ar
                                            ) {
                                                return ar.indexOf(item) === i;
                                            });

                                            if (uniques.length > 1) {
                                                deliveryCharge =
                                                    uniques.length * 10 -
                                                    10 +
                                                    parseInt(this.state.dprice);
                                            } else {
                                                deliveryCharge = parseInt(
                                                    this.state.dprice
                                                );
                                            }
                                            if (cartItems[key].gst_value) {
                                                // gst_percent_total = gst_percent_total + (cartItems[key].gst_value*(cartItems[key].quantity*cartItems[key].price))/100;
                                                gst_percent_total =
                                                    gst_percent_total +
                                                    (cartItems[key].gst_value *
                                                        (cartItems[key]
                                                            .quantity *
                                                            cartItems[key]
                                                                .price +
                                                            cartItems[key]
                                                                .quantity *
                                                            cartItems[key]
                                                                .packing_charge)) /
                                                    100;
                                            }

                                            totalPrice =
                                                totalPrice +
                                                cartItems[key].price *
                                                cartItems[key].quantity;
                                            if (cartItems[key].packing_charge) {
                                                totalPackCharge =
                                                    totalPackCharge +
                                                    parseFloat(
                                                        cartItems[key]
                                                            .packing_charge
                                                    ) *
                                                    cartItems[key].quantity;
                                            }

                                            return (
                                                <tr>
                                                    <td className="priceWidth">
                                                        {
                                                            cartItems[key]
                                                                .food_name
                                                        }
                                                    </td>
                                                    <td className="priceWidth">
                                                        {
                                                            cartItems[key]
                                                                .quantity
                                                        }
                                                    </td>
                                                    <td className="priceWidth">
                                                        ₹ {cartItems[key].price}
                                                        {cartItems[key]
                                                            .offer !== null ? (
                                                                <span>
                                                                    <br />
                                                                    <i
                                                                        style={{
                                                                            paddingLeft: 2,
                                                                            fontSize: 10,
                                                                            color:
                                                                                "green"
                                                                        }}
                                                                    >
                                                                        ( {cartItems[key]
                                                                            .offer}% OFF )
                                                                </i>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                                <tbody>

                                    <tr>
                                        <td className="priceWidthTotal">
                                            Packing Charge:-
                                                <br />
                                            {totalQuantityPackCharge ?
                                                <span>
                                                    Quantity:{" "}
                                                    {totalQuantityPackCharge}
                                                </span> : ""}
                                        </td>
                                        <td className="priceWidthTotal">
                                            ₹ {totalPackCharge}
                                        </td>
                                    </tr>


                                    <tr className="tdTotalFoodName">
                                        <td className="priceWidthTotal">
                                            GST :-
                                            </td>
                                        <td className="priceWidthTotal">
                                            ₹ {gst_percent_total}
                                        </td>
                                    </tr>
                                    {
                                        Math.round(
                                            totalPackCharge +
                                            totalPrice +
                                            gst_percent_total
                                        ) > parseInt(this.state.min_amount) ? (<tr>
                                            <td className="priceWidthTotal">Delivery Charge :-</td>
                                            <td className="priceWidthTotal">
                                                ₹ 0 <br /><span>
                                                    <i>
                                                        (Free Delivery eligible.
                                                        Please order now)
                                                    </i>
                                                </span>
                                            </td>
                                        </tr>) : ((t >= this.state.time_from && t <= this.state.time_upto) ? (<tr>
                                            <td className="priceWidthTotal">Delivery Charge :-</td>
                                            <td className="priceWidthTotal">
                                                ₹ 0
                                                        <br />
                                                <span>
                                                    <i>
                                                        (Free Delivery between{" "}
                                                        {
                                                            this.state
                                                                .time_from
                                                        }{" "}
                                                                    and{" "}
                                                        {
                                                            this.state
                                                                .time_upto
                                                        }
                                                                    . Place order now)
                                                    </i>
                                                </span>
                                            </td>
                                        </tr>) : (this.props.sameDeliveryFlag ? <tr>
                                            <td className="priceWidthTotal">Delivery Charge :-</td>
                                            <td className="priceWidthTotal">
                                                ₹ {this.props.sameDeliveryCharge}
                                            </td>
                                        </tr> : (this.props.restDetails.restDetails.gst_number !== "" ? <tr>
                                            <td className="priceWidthTotal">Delivery Charge :-</td>
                                            <td className="priceWidthTotal">
                                                ₹ {this.props.restDetails.restDetails.gst_number}
                                            </td>
                                        </tr> : <tr>
                                                <td className="priceWidthTotal">Delivery Charge :-</td>
                                                <td className="priceWidthTotal">
                                                    ₹ 0
                                            </td>
                                            </tr>)))
                                    }


                                    {/*this.state.dprice === "" ? (
                                        ""
                                    ) : Math.round(
                                        totalPackCharge +
                                        totalPrice +
                                        gst_percent_total
                                    ) >
                                        parseInt(
                                            this.state.min_amount
                                        ) ? (
                                                <tr>
                                                    {" "}
                                                    <td className="priceWidthTotal">
                                                        Delivery Charge
                                            </td>
                                                    <td className="priceWidthTotal">
                                                        ₹ 0<br />
                                                        <span>
                                                            <i>
                                                                (Free Delivery eligible.
                                                                Please order now)
                                                    </i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ) : t >=
                                                this.state.time_from &&
                                                t <=
                                                this.state.time_upto ? (
                                                    <tr>
                                                        {" "}
                                                        <td className="priceWidthTotal">
                                                            Delivery Charge
                                            </td>
                                                        <td className="priceWidthTotal">
                                                            ₹ 0 <br />
                                                            <span>
                                                                <i>
                                                                    (Free Delivery between{" "}
                                                                    {
                                                                        this.state
                                                                            .time_from
                                                                    }{" "}
                                                                    and{" "}
                                                                    {
                                                                        this.state
                                                                            .time_upto
                                                                    }
                                                                    . Place order now)
                                                    </i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        {" "}
                                                        <td className="priceWidthTotal">
                                                            Delivery Charge
                                            </td>
                                                        <td className="priceWidthTotal">
                                                            ₹ {deliveryCharge}
                                                        </td>
                                                    </tr>
                                                )*/}
                                    {

                                    }

                                    {this.state.dprice === "" ? "" : Math.round(
                                        totalPackCharge +
                                        totalPrice +
                                        gst_percent_total
                                    ) >
                                        parseInt(
                                            this.state.min_amount
                                        ) ? (
                                            <tr>
                                                <td className="priceWidthTotal">
                                                    <b>Total:- </b>
                                                </td>
                                                <td className="priceWidthTotal">
                                                    <b>
                                                        ₹{" "}
                                                        {Math.round(
                                                            totalPackCharge +
                                                            totalPrice +
                                                            gst_percent_total
                                                        )}
                                                    </b>
                                                </td>
                                            </tr>
                                        ) : t >=
                                            this.state.from_time &&
                                            t <=
                                            this.state.upto_time ? (
                                                <tr>
                                                    <td className="priceWidthTotal">
                                                        <b>Total:- </b>
                                                    </td>
                                                    <td className="priceWidthTotal">
                                                        <b>
                                                            ₹{" "}
                                                            {Math.round(
                                                                totalPackCharge +
                                                                totalPrice +
                                                                gst_percent_total
                                                            )}
                                                        </b>
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td className="priceWidthTotal">
                                                        <b>Total:- </b>
                                                    </td>
                                                    <td className="priceWidthTotal">
                                                        <b>
                                                            ₹{" "}
                                                            {Math.round(
                                                                totalPackCharge +
                                                                parseInt(
                                                                    deliveryCharge
                                                                ) +
                                                                totalPrice +
                                                                gst_percent_total
                                                            )}
                                                        </b>
                                                    </td>
                                                </tr>
                                            )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Grid>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        total: calculateTotal(state.cart),
        cartItems: state.cart,
        authentication: state.authentication,
        restDetails: state.rest
    };
};
export default connect(mapStateToProps)(BillingForm);
