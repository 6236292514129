import React, { Component } from 'react';
import { callBackend } from "./utils";
import * as Settings from "../Config.json";
class RazorpayPayment extends Component {
    startRazorpay() {

        var user = JSON.parse(localStorage.getItem("user"));

            //var user_key = user.key;
            //var user_login_name = user.username;
            var address = localStorage.getItem("addressInfo");
            var cartItems = localStorage.getItem("cartItemss");
            var totalPrice = localStorage.getItem("totalPrice");
            var paymode = localStorage.getItem("paymentMode");
        //-------------------
      

    }

    componentDidMount() {
      
        this.startRazorpay();

    }
    render() {
        return (
            <div>
                
            </div>
        );
    }
}

export default RazorpayPayment;