import { Carousel, Glyphicon } from "react-bootstrap";
import "./CarousalHome.css";
import * as Settings from "../Config.json";
import React, { Component } from "react";

class CarousalHome extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isHover: false,
            slideItems: []
        };

        this.handleMouseOver = this.handleMouseOver.bind(this);
    }
    componentDidMount() {
        const that = this;
        fetch(Settings.baseurl + "/api/slide/")
            .then(function(response) {
                return response.json();
            })
            .then(function(jsonData) {
                return jsonData;
            })
            .then(function(jsonStr) {
                that.setState({ slideItems: jsonStr });
            });
    }

    handleMouseOver() {
        this.setState({ isHover: true });
    }

    render() {
        const { isHover } = this.state;

        const pointerL = isHover ? <Glyphicon glyph="chevron-left" /> : null;
        const pointerR = isHover ? <Glyphicon glyph="chevron-right" /> : null;

        return (
            <div
                onMouseOver={this.handleMouseOver}
               // onFocus
                onMouseLeave={() => this.setState({ isHover: false })}
               // onBlur
                className="container"
                style={{ padding: "0px" }}
            >
                <Carousel prevIcon={pointerL} nextIcon={pointerR}>
                    {this.state.slideItems.slice(0).map((element, key) => {
                        const str = element.photo1;
                        var a = str;
                        var b = "s";
                        var position = 4;
                        var output = [
                            a.slice(0, position),
                            b,
                            a.slice(position)
                        ].join("");
                        return (
                            <Carousel.Item key={key}>
                                <img alt="Smiley face" src={str} />
                            </Carousel.Item>
                        );
                    })}
                </Carousel>
            </div>
        );
    }
}

export default CarousalHome;
