import React, { Component, PropTypes } from "react";
import { Grid, Row, Col, Table, Button } from "react-bootstrap";
class InstamojoRedirect extends Component {
    render() {
        return (
            <div
                style={{
                    marginTop: " 80px"
                }}
            >
                <Grid>Payment Gateway</Grid>
            </div>
        );
    }
}

InstamojoRedirect.propTypes = {};

export default InstamojoRedirect;
