import React, { Component, PropTypes } from "react";
import { Col, Image, Button } from "react-bootstrap";

import "./HomePickOfTheDay.css";
import * as NumericInput from "react-numeric-input";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CartModel from "./CartModel";
import * as actionCreators from "../actions";

class AddOnFoodItemCart extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            numInput: 1,
            cart: [],
            showCartButton: false,
            isLoading: false,
            openState: false
        };
        this.onChangeInputHandler = this.onChangeInputHandler.bind(this);
        this.addToCart = this.addToCart.bind(this);
    }

    onChangeInputHandler(value) {
        this.setState({ numInput: value });
    }

    addToCart(e, pic, quantity) {
        if (pic.available === true) {
            alert("Currently, Item not available")
        } else {
            if (pic.rest_closed) {
                alert("Sorry! The restaurant " + pic.restaurant_name + " is closed for today.")
            } else {
                var currentTime = new Date();
                var t = currentTime.getHours() + ":" + currentTime.getMinutes();
                //    console.log('time '+t);

                if (t >= pic.rest_open_time && t <= pic.rest_close_time) {

                    var tt = this.props.cartItems;
                    if (tt === null || tt === undefined) {
                        var packing_charge = pic.packing_charge === "" || pic.packing_charge === null ? 0 : parseInt(pic.packing_charge);
                        var gst_charge = pic.gst_value === "" || pic.gst_value === null ? 0 : parseInt(pic.gst_value);
                        var sub_total = parseInt(quantity) * (packing_charge + parseInt(pic.price) + (gst_charge * (parseInt(pic.price) + packing_charge) / 100))
                        // console.log("sub total ..."+Math.ceil(sub_total));
                        //var quant = { quantity: quantity, sub_total: Math.ceil(sub_total)}
                        // console.log("qunatity ....."+JSON.stringify(quantity));
                        var sub_total = Math.ceil(sub_total);



                        const object = { quantity, sub_total, ...pic };
                        // console.log("objects"+JSON.stringify(object));
                        const value = this.props.actions.addItem(object);
                        this.setState({ numInput: 1 });
                    } else {
                       /* var t1 = Object.keys(tt).map(key => {

                            if (pic.restaurant !== tt[key].restaurant) {
                                //  console.log("if1")
                                // this.setState({preRest: item.restaurant_name})
                                return false;

                            } else {
                                return true;
                            }
                        });


                        if (t1.includes(false)) {
                            alert("Only one order per Restaurant is allowed!\n\nPlease remove the previous selection from your Cart.")
                        } else {*/

                            // console.log(JSON.stringify(pic));
                            var packing_charge = pic.packing_charge === "" || pic.packing_charge === null ? 0 : parseInt(pic.packing_charge);
                            var gst_charge = pic.gst_value === "" || pic.gst_value === null ? 0 : parseInt(pic.gst_value);
                            var sub_total = parseInt(quantity) * (packing_charge + parseInt(pic.price) + (gst_charge * (parseInt(pic.price) + packing_charge) / 100))
                            // console.log("sub total ..."+Math.ceil(sub_total));
                            //var quant = { quantity: quantity, sub_total: Math.ceil(sub_total)}
                            // console.log("qunatity ....."+JSON.stringify(quantity));
                            var sub_total = Math.ceil(sub_total);


                            const object = { quantity, sub_total, ...pic };
                            // console.log("objects ......"+JSON.stringify(object));
                            const value = this.props.actions.addItem(object);
                            this.setState({ numInput: 1 });
                      //  }
                    }

                }
                else {
                    alert("Restaurant closed.")
                }
            }



        }



    }
    checkType(typeId) {
        let picType = "";
        if (typeId === 2) {
            picType =
                "https://png.icons8.com/color/50/000000/non-vegetarian-food-symbol.png";
        }
        if (typeId === 1) {
            picType =
                "https://png.icons8.com/color/50/000000/vegetarian-food-symbol.png";
        }
        return picType;
    }

    render() {
        const { pic, flag } = this.props;
        const totalPrice = pic.price * this.state.numInput;
        const picType = this.checkType(pic.food_type);
        var img = "";
        /*
         *Previous code to add s in http: link
         */

        /* const str = pic.food_image;
         var a = str;
         var b = "s";
         var position = 4;
         var img = [a.slice(0, position), b, a.slice(position)].join("");*/
        var imgFlag = false;
        if (flag === "rest") {
            const str2 = pic.food_image;
            var str1 = "https://api-v3.tasteofimphal.com";
            var n = str2.includes("https://api-v3.tasteofimphal.com");
            img = str2;
            if (n === false) {
                //var str1 = "Hello ";
                //var str2 = "world!";
                imgFlag = true;
                img = str1.concat(str2);
            }
        }

        return (
            <Col className="col-wrapper">
                {imgFlag === true ? <img src={img} className="f-img" /> : <img src={pic.food_image} className="f-img" />}
                <div className="homepickday-food-name">
                    <img
                        src={picType}
                        className="pickofday-food-type"
                        alt="no"
                    />
                    {pic.offer !== null ? <p className="top-left">{pic.offer}% OFF</p> : (pic.tagline !== null ? <p className="top-left-tagline">{pic.tagline}</p> : "")}

                    &emsp;
                    <h4 className="fname">{pic.food_name}</h4>

                </div>
                {pic.offer === null ? <h4 className="fnameOldPrice">₹{totalPrice}</h4> : <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}><h4 className="fnameOldPrice"><strike>₹ {pic.old_price}</strike></h4> <h4 className="fnamePrice">₹{totalPrice}</h4> </div>}
                <div className="homepickday-addsection">
                    <Button
                        bsStyle="success"
                        className="add-cart-button"
                        onClick={e => {
                            this.addToCart(e, pic, this.state.numInput);
                        }}
                    >
                        Add
                    </Button>
                    &emsp;
                    <div className="num-input">
                        <NumericInput
                            mobile
                            className="form-control"
                            min={1}
                            max={20}
                            value={this.state.numInput}
                            onChange={this.onChangeInputHandler}
                        />
                    </div>
                </div>
            </Col>
        );
    }
}

AddOnFoodItemCart.propTypes = {};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actionCreators, dispatch)
});
const mapStateToProps = state => {
    return {
        cartItems: state.cart
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddOnFoodItemCart);
