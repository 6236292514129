import { restConstants } from "../constants";

export const addRest = item => {
    return {
        type: restConstants.ADD_REST,
        item
    };
};
export const getRest = () => {
    return {
        type: restConstants.GET_REST
    };
};
export const removeRest = id => {
    return {
        type: restConstants.REMOVE_REST,
        id
    };
};
