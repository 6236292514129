import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import "./RestaurantDetails.css";
import Slider from "react-slick";
import { callBackend } from "./utils";
import * as Settings from "../Config.json";
import RestuarantMenu from "./RestuarantMenu";
import FoodItemCart from "./FoodItemCart";
import Loader from 'react-loader-spinner'
import ReactLoading from "react-loading";
import ReactGA from "react-ga";
import AddOnFoodItemCart from './AddOnFoodItemCart';
import rclose from '../images/rclose.jpg';
const Loader1 = ({ type, color }) => (
    <ReactLoading type="bars" color="#a79b9b" height={"5%"} width={"5%"} />
);
const LoaderMenu = ({ type, color }) => (
    <ReactLoading type="bars" color="#a79b9b" height={"5%"} width={"5%"} />
);

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className="fa fa-angle-right"
            style={{
                display: "block",
                background: "#e8e4e491",
                right: "5px",
                color: "white",
                position: "absolute",
                top: "130px",
                height: "40px",
                width: "30px",
                paddingTop: "7px",
                fontSize: "28px",
                borderBottomLeftRadius: "5px",
                borderTopLeftRadius: "5px"
            }}
            onClick={onClick}
            onKeyDown={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className="fa fa-angle-left"
            style={{
                display: "block",
                background: "#e8e4e491",
                left: "5px",
                color: "white",
                position: "absolute",
                zIndex: "1",
                top: "130px",
                height: "40px",
                width: "30px",
                paddingTop: "7px",
                fontSize: "28px",
                borderBottomRightRadius: "5px",
                borderTopRightRadius: "5px"
            }}
            onClick={onClick}
            onKeyDown={onClick}
        />
    );
}
export default class AddOnFoodList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // categoryImgs: [],
            restName: "",
            restAddress: "",
            restTime: "",
            restGst: "",
            restFoods: [],
            fcategory: [],
            loadingPriority: true,
            loadingMenu: true,
            spinner: false,
            
        };
        this.restaurantUpdate = this.restaurantUpdate.bind(this);
    }

    restaurantUpdate() {
        /* this.setState({ hi: this.props.match.params.resId });*/
        this.setState({
            spinner: true
        })
        var id = this.props.match.params.id;
        const header = new Headers();
        callBackend(
            "/api/restaurantweb/" + 28 + "/",
            response => {
                const restName = response.restaurant_name;
                this.setState({ restName });
                const restAddress = response.address;
                this.setState({ restAddress });
                const restTime = response.restaurant_time;
                this.setState({ restTime });
                const restGst = response.gst;
                this.setState({ restGst });
                const restFoods = response.foods;
                window.scrollTo(0, 0)
                this.setState({ restFoods });
                this.setState({ loadingPriority: false, spinner: false });
            },
            header
        );

        callBackend(
            "/api/autocompletecategory/",
            response => {
                this.setState({ fcategory: response });
                this.setState({ loadingMenu: false, spinner: false });
                window.scrollTo(0, 0)
            },
            header
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.restaurantUpdate();
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.restaurantUpdate();
    }

    checkType(typeId) {
        let picType = "";
        if (typeId === 2) {
            picType =
                "https://png.icons8.com/color/50/000000/non-vegetarian-food-symbol.png";
        }
        if (typeId === 1) {
            picType =
                "https://png.icons8.com/color/50/000000/vegetarian-food-symbol.png";
        }
        return picType;
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: false,
                        nextArrow: <SampleNextArrow />,
                        prevArrow: <SamplePrevArrow />
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2,
                        nextArrow: <SampleNextArrow />,
                        prevArrow: <SamplePrevArrow />
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1.05,
                        slidesToScroll: 1,
                        nextArrow: false,
                        prevArrow: false
                    }
                }
            ]
        };
        ReactGA.pageview(window.location.pathname + window.location.search);

        var currentTime = new Date();
        var t =currentTime.getHours()  + ":" + currentTime.getMinutes();
        var closeFlag = true
       // console.log("flag "+pic.open_time)
        var openTime = "";
        var closeTime = "";
        this.state.restFoods.length !== 0 && (openTime = this.state.restFoods[0]['rest_open_time'])
        this.state.restFoods.length !== 0 && (closeTime = this.state.restFoods[0]['rest_close_time'])
        if(t >= openTime && t <= closeTime){
          closeFlag = false
        }
        // ReactGA.pageview('/homepage');
        if (this.state.spinner) {
            return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Loader
                    type="Oval"
                    color="#FFA500"
                    height="100"
                    width="100"
                /></div>)
        } else {
        return (
            <div>
                <br />
                <br />
                <br />
                <br />
                <Grid style={{ padding: "0px" }}>
                    <Row className="rest-banner">
                        <Col md={8}>
                            <h2 className="col-child-element">
                                TOI's Add On Items
                            </h2>
                        </Col>

                        <Col md={4}>
                            <h4 className="rest-time-h4 pull-right">
                                <i className="fa fa-clock-o" />{" "}
                                {this.state.restTime}
                            </h4>
                        </Col>
                    </Row>

                    <br />
                    {/*<img
                alt="Taste of Imphal"
                className="restaurant-close"
                src={rclose}
                    />*/}

                  {/*  <h3 className="rest-heading">
                        <span className="underline">
                            Add On Items
                        </span>
                    </h3>
                
                <br />*/}
                    <br />
                    {this.state.loadingPriority ? (
                        <div className="loader">
                            <Loader1 />
                        </div>
                    ) : (
                        <Row className="fclist-row show-grid text-center">
                           
                                {this.state.restFoods.map(pic => {
                                    if (pic.priority === true) {
                                        return (
                                            <div className="foodItems-fclist">
                                            {" "}
                                            <AddOnFoodItemCart
                                                pic={pic}
                                                flag={"rest"}
                                            />
                                            </div>
                                        );
                                    }
                                })}
                        </Row>
                    )}
                    {/*<h3 className="rest-heading">
                        <span className="underline">
                            {this.state.restName}
                            's Menu
                        </span>
                    </h3>
                    <br />
                    <br />
                    <Table responsive>
                        {this.state.loadingMenu ? (
                            <div className="loader">
                                <LoaderMenu />
                            </div>
                        ) : (
                            <tbody>
                                {this.state.fcategory.map(element => {
                                    let hotFoods = this.state.restFoods.filter(
                                        food => food.category === element.id
                                    );

                                    let highPriorityItems = hotFoods.filter(
                                        prioirtyelem =>
                                            prioirtyelem.priority === false
                                    );
                                    //highPriorityItems.ForEach((items) => console.log(items));
                                    return highPriorityItems.length > 0 ? (
                                        <RestuarantMenu
                                            header={element.food_catagory}
                                            items={highPriorityItems}
                                        />
                                    ) : null;
                                })}
                            </tbody>
                        )}
                            </Table>*/}
                </Grid>
            </div>
        );
                            }
    }
}
