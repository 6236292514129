import React, { Component } from "react";
import { Grid, Col, Image } from "react-bootstrap";
import "./About.css";

export default class Jobs extends Component {
    render() {
        return (
            <div className="about-root-div">
                <Grid>
                    <Col xs={12} sm={8} smOffset={2}>
                        <h1 className="about-h1">
                            Opportunities in Taste of Imphal
                        </h1>
                        <b>Job Summary</b>
                        <br />
                        <p>Delivery executives for food delivery.</p>
                        <p>Flexible working hours.</p>
                        <p>Full Time.</p>
                        <b>Documents Required</b>
                        <br />
                        <ul>
                            <li>
                                <i>Driving Licence</i>
                            </li>
                            <li>
                                <i>RC</i>
                            </li>
                            <li>
                                <i>Aadhaar Card / voter ID</i>
                            </li>
                        </ul>

                        <b>Responsibilities and Duties</b>
                        <br />
                        <p>
                            Responsible for collecting the food from restaurant.
                        </p>
                        <p>
                            Ensure safe delivery of food items at the customer's
                            place on time.
                        </p>
                        <p>
                            Cross check if correct food items are picked up and
                            delivered.
                        </p>
                        <p>
                            Responsible for collecting the right amount of
                            payment from the customers.
                        </p>
                        <p>Must possess Time management</p>
                        <b>Required Experience, Skills and Qualifications</b>
                        <br />
                        <p>Communication skills</p>
                        <p>Two-Wheeler</p>
                        <p>Driving License</p>
                        <p>Smartphone</p>
                        <p>Aadhar card/Voter ID</p>
                        <b>Benefits</b>
                        <br />
                        <p>Attractive monthly Incentives</p>
                        <p>Rewards and Recognition</p>
                        <p>Job Type: Full-time</p>
                        <p>Salary: ₹5,000 - ₹8,000.00 /month</p>
                    </Col>
                </Grid>
            </div>
        );
    }
}
