import { cartConstants } from "../constants";

export const addItem = item => {
    return {
        type: cartConstants.ADD_CART,
        item
    };
};
export const getItems = () => {
    return {
        type: cartConstants.GET_CART
    };
};
export const removeItems = id => {
    return {
        type: cartConstants.REMOVE_CART,
        id
    };
};
