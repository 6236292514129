import React, { Component } from "react";
import { Grid, Row, Col, Table, Image, Button } from "react-bootstrap";
import "./FoodCategoryList.css";
import * as NumericInput from "react-numeric-input";
import { callBackend } from "./utils";
import * as Settings from "../Config.json";
import FoodItemCart from "./FoodItemCart";

import FoodCategoryMenu from "./FoodCategoryMenu";
import ReactGA from "react-ga";
import Loader from 'react-loader-spinner'
export default class FoodCategoryList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // categoryImgs: [],
            categoryfoodName: "",

            categoryFoods: [],
            spinner: false,

            // categoryName: '',
            //foodName: ""
        };
        this.foodCategoryUpdate = this.foodCategoryUpdate.bind(this);
    }

    foodCategoryUpdate() {
        var id = this.props.match.params.id;
        this.setState({
            spinner: true,
        })
        const header = new Headers();
        window.scrollTo(0, 0)

        fetch(Settings.baseurl +"/api/foodcategory/" + id + "/")
        .then(Response => Response.json())
        .then(findresponse => {
            
                const categoryfoodName = findresponse.food_catagory;
                this.setState({ categoryfoodName });
                const categoryFoods = findresponse.foods;
                this.setState({ categoryFoods, spinner: false });
                window.scrollTo(0, 0)
           
        });
       /* callBackend(
            Settings.baseurl + "/api/foodcategory/" + id + "/",
            response => {
                const categoryfoodName = response.food_catagory;
                this.setState({ categoryfoodName });
                const categoryFoods = response.foods;
                this.setState({ categoryFoods, spinner: false });
                window.scrollTo(0, 0)
            },
            header
        );*/
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.foodCategoryUpdate();
            
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.foodCategoryUpdate();
    }

    checkType(typeId) {
        let picType = "";
        if (typeId === 2) {
            picType =
                "https://png.icons8.com/color/50/000000/non-vegetarian-food-symbol.png";
        }
        if (typeId === 1) {
            picType =
                "https://png.icons8.com/color/50/000000/vegetarian-food-symbol.png";
        }
        return picType;
    }

    render() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        if (this.state.spinner) {
            return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Loader
                    type="Oval"
                    color="#FFA500"
                    height="100"
                    width="100"
                /></div>)
        } else {
        return (
            <Grid>
                <Row className="fc-list-banner">
                    <Col md={12}>
                        <h2 className="food-header-text text-center">
                            {this.state.categoryfoodName}
                        </h2>
                    </Col>
                </Row>
                <Row className="fclist-row show-grid text-center">
                    {this.state.categoryFoods.map(pic => {
                        // console.log("><><><><><><>"+JSON.stringify(pic))
                        /* 
                       //https://api-v3.tasteofimphal.com/imagess/None/no-img.jpg
                       const str2 = pic.food_image;
                       var str1 = "https://api-v3.tasteofimphal.com";
                       var n = str2.includes("https://api-v3.tasteofimphal.com");
                       var imgs = str2
                       if(n === false){
                           //var str1 = "Hello ";
                           //var str2 = "world!";
                           var imgs = str1.concat(str2);
                       }*/
                        var a = pic.food_image;
                        /*var b = "s";
                        var position = 4;
                        var output = [
                            a.slice(0, position),
                            b,
                            a.slice(position)
                        ].join("");*/
                        if (
                            a !==
                                Settings.baseurl+"/imagess/None/no-img.jpg" &&
                            pic.priority === true
                        ) {
                            if (
                                a !==
                                    Settings.baseurl+"/imagess/None/no-img.jpg" &&
                                pic.priority === true
                            ) {
                                return (
                                    <div className="foodItems-fclist">
                                        {" "}
                                        <FoodItemCart
                                            pic={pic}
                                            flag={"noimg"}
                                        />
                                    </div>
                                );
                            }
                        }
                    })}
                </Row>
                <Table className="fclist-row" responsive>
                    <thead>
                        <tr>
                            <th>
                                {this.state.categoryfoodName}
                                's Menu
                            </th>
                            <th />
                            <th />
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.categoryFoods.map(pic => {
                            if (
                                pic.food_image ===
                                    Settings.baseurl+"/imagess/None/no-img.jpg" ||
                                pic.food_image ===
                                    Settings.baseurl+"/imagess/None/no-img.jpg"
                            ) {
                                return <FoodCategoryMenu items={pic} />;
                            }
                            return null;
                        })}
                    </tbody>
                </Table>
            </Grid>
        );
                    }
    }
}
