import { userConstants } from "../constants";

export const login = user => {
    return {
        type: userConstants.LOGIN_SUCCESS,
        user
    };
};
export const loginRequest = () => {
    return {
        type: userConstants.LOGIN_REQUEST
    };
};

export const loginReset = () => {
    return {
        type: userConstants.LOGIN_RESET
    };
};

export const logOut = () => {
    return {
        type: userConstants.LOGOUT
    };
};
