import React, { Component } from "react";
import "./CustomNavbar.css";
import { Button, Modal, ButtonToolbar, Image, Table } from "react-bootstrap";
import "./CartModel.css";
import ReactInterval from "react-interval";
import CartTable from "./CartTable";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Settings from "../Config.json";

function calculateTotal(items) {
    let total = 0;
    items !== null &&
        Object.keys(items).forEach(key => (total += items[key].quantity));
    return total;
}

class CartModel extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            show: false,
            setFlag: false,
            cartCount: 0,
            offerData: [],
            delivery_charge: "80",
            sameDeliveryFlag: false,
            sameDeliveryCharge: ""
            //localData: null,
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.localStorageCheck = this.localStorageCheck.bind(this);
        var handleToUpdate = this.handleToUpdate.bind(this);
        var arg1 = "";
    }

    componentDidMount() {
        fetch(Settings.baseurl + "/api/deliveryoffer/")
            .then(Response => Response.json())
            .then(findresponse => {
                this.setState({ 
                    offerData: findresponse, 
                    sameDeliveryFlag:  findresponse[0]["delivery_charge_flag"], 
                    sameDeliveryCharge: findresponse[0]["delivery_charge"] 
                });
            
              //  var test1 = Object.keys(this.props.cartItems);
              //  console.log("object key "+JSON.stringify(test1[0]));
                //this.setState({ loading: false });
                /*
                fetch(Settings.baseurl + "/api/priority_rest/"+rid+"/")
                .then(Response => Response.json())
                .then(response => {
                  this.setState({
                    addedCart: true
                  })
                });  
                */
            });
    }

    handleToUpdate(someArg) {
        //alert('We pass argument from Child to Parent: ' + someArg);
        //  this.setState({ show: someArg });
        fetch(Settings.baseurl + "/api/version_check/")
            .then(Response => Response.json())
            .then(findresponse1 => {

                if (findresponse1[0]["service_flag"]) {

                    alert("Our Service is OFF for today");
                    this.setState({ show: false });
                    window.location.href =
                        window.location.origin;
                } else {
                    var currentTime = new Date();
                    var hrs = ('0'+currentTime.getHours()).substr(-2);
                    var t = hrs + ":" + currentTime.getMinutes();
                 

                    if (t >= findresponse1[0]["service_open_time"] && t <= findresponse1[0]["service_close_time"]) {
                        this.setState({ show: someArg });
                    } else {

                        alert("Service closed.")
                        this.setState({ show: false });
                        window.location.href =
                            window.location.origin;
                    }
                }
            });
    }
    componentWillMount() {
        this.state.cartCount = localStorage.length;
    }

    handleShow() {
        this.setState({ show: true });
    }

    handleHide() {
        this.setState({ show: false });
    }
    getInitialState() {
        return { count: 0 };
    }
    localStorageCheck() {
        if (localStorage.length === 0) {
            return false;
        }
        return true;
    }

    render() {
        const { cartItems, total, restDetails } = this.props;
        var handleToUpdate = this.handleToUpdate;
        return (
            <ButtonToolbar>
                <div className="ex4">
                    <span
                        style={{ top: "-15px", fontSize: "25px" }}
                        className="p1 fa-stack fa-2x has-badge"
                        data-count={total}
                        onClick={this.handleShow}
                    >
                        <i
                            style={{ fontSize: "20px" }}
                            className="p3 fa fa-shopping-cart fa-stack-1x xfa-inverse"
                            data-count="4b"
                        />
                    </span>
                </div>
                <Modal
                    {...this.props}
                    show={this.state.show}
                    onHide={this.handleHide}
                    dialogClassName="custom-modal"
                >
                    <Modal.Header closeButton>
                        <div className="addonDiv">
                        <Modal.Title>Your Cart</Modal.Title>
                       {/* <Link to="/addOnFoodList">
                            <Button
                                variant="outline-primary"
                                style={{ marginLeft: "20px" }}
                                onClick={this.handleHide}
                            >
                                Add On
                            </Button>
        </Link>*/}
                        </div>
                      
                    </Modal.Header>
                    <Modal.Body>
                        <CartTable
                            data={cartItems}
                            restDetails={restDetails}
                            offerData={this.state.offerData}
                            sameDeliveryCharge={this.state.sameDeliveryCharge}
                            sameDeliveryFlag={this.state.sameDeliveryFlag}
                            handleToUpdate={handleToUpdate.bind(this)}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleHide}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </ButtonToolbar>
        );
    }
}
const mapStateToProps = state => {
    return {
        total: calculateTotal(state.cart),
        cartItems: state.cart,
        restDetails: state.rest
    };
};
export default connect(mapStateToProps)(CartModel);
