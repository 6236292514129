import React, { Component } from "react";
import { Grid, Col, Image } from "react-bootstrap";
import "./About.css";
import ReactGA from "react-ga";
export default class About extends Component {
    render() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        return (
            <div className="about-root-div">
                <Grid>
                    <Col xs={12} sm={8} smOffset={2}>
                        <h1 className="about-h1">Welcome to Taste of Imphal</h1>
                        <p className="about-p-text">
                            We at Taste of Imphal are delighted to offer you our
                            online active food consultancy services -
                            ‘www.tasteofimphal.com’. We provide our servics to
                            make more comfortable for the customers as well as
                            for the restuarants. For a memorable meal the
                            quality of the service is something that guests
                            often remember as much as the food and drink served.
                            That means that restaurant servers must show
                            extensive knowledge of all types of cuisine and
                            dishes, especially the ingredients and cooking style
                            of the dishes on an a la carte menu. They will need
                            to know and understand the methods of preparation
                            and serving, along with the tools used. Regardless
                            of whether it is a self-service cafeteria or a five
                            star hotel restaurant good manners, a smart
                            appearance, and impeccable personal hygiene are
                            equally essential. For a memorable meal the quality
                            of the service is something that guests often
                            remember as much as the food and drink served. That
                            means that restaurant servers must show extensive
                            knowledge of all types of cuisine and dishes,
                            especially the ingredients and cooking style of the
                            dishes on an a la carte menu. They will need to know
                            and understand the methods of preparation and
                            serving, along with the tools used. Regardless of
                            whether it is a self-service cafeteria or a five
                            star hotel restaurant good manners, a smart
                            appearance, and impeccable personal hygiene are
                            equally essential.
                        </p>
                    </Col>
                </Grid>
            </div>
        );
    }
}
