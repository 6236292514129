import React, { Component } from "react";
import { Grid, Row, Col, Image, Button } from "react-bootstrap";
import "./HomePickOfTheDay.css";
import Slider from "react-slick";
import FoodItemCart from "./FoodItemCart";
import { callBackend } from "./utils";
import * as Settings from "../Config.json";
// import PropTypes from 'prop-types';

import ReactLoading from "react-loading";

const Loader = ({ type, color }) => (
    <ReactLoading type="bars" color="#a79b9b" height={"5%"} width={"5%"} />
);

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className="fa fa-angle-right"
            style={{
                display: "block",
                background: "#e8e4e491",
                right: "5px",
                color: "white",
                position: "absolute",
                top: "120px",
                height: "40px",
                width: "30px",
                paddingTop: "7px",
                fontSize: "28px",
                borderBottomLeftRadius: "5px",
                borderTopLeftRadius: "5px"
            }}
            onClick={onClick}
            onKeyDown={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className="fa fa-angle-left"
            style={{
                display: "block",
                background: "#e8e4e491",
                left: "5px",
                color: "white",
                position: "absolute",
                zIndex: "1",
                top: "120px",
                height: "40px",
                width: "30px",
                paddingTop: "7px",
                fontSize: "28px",
                borderBottomRightRadius: "5px",
                borderTopRightRadius: "5px"
            }}
            onClick={onClick}
            onKeyDown={onClick}
        />
    );
}

export default class HomePickOfTheDay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pickOfDayData: [],
            loading: true
        };
    }

    componentDidMount() {
        const header = new Headers();
        /*callBackend(
            Settings.baseurl + "/api/today_pick/",
            response => {
                const pickOfDayData = response;
                this.setState({ pickOfDayData });
                this.setState({ loading: false });
            },
            header
        );*/
       
        fetch(Settings.baseurl+"/api/today_pick/")
        .then(Response => Response.json())
        .then(findresponse => {
            this.setState({ pickOfDayData: findresponse });
            this.setState({ loading: false });
        });
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: false,
                        nextArrow: <SampleNextArrow />,
                        prevArrow: <SamplePrevArrow />
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2,
                        nextArrow: <SampleNextArrow />,
                        prevArrow: <SamplePrevArrow />
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1.05,
                        slidesToScroll: 1,
                        nextArrow: false,
                        prevArrow: false
                    }
                }
            ]
        };

        return (
            <Grid
                style={{
                    textAlign: "center",
                    marginTop: "20px",
                    padding: "0px"
                }}
            >
                <h3 id="headings">
                    <span className="underline">Our pick of the day</span>
                </h3>

                {this.state.loading ? (
                    <div className="loader">
                        <Loader />
                    </div>
                ) : (
                    <Row className="show-grid text-center">
                        <Slider {...settings}>
                            {this.state.pickOfDayData.map((pic, key) => {
                                //console.log(">>>>>>>>>>>>>>>>>"+JSON.stringify(pic));
                                return <FoodItemCart pic={pic} key={key}/>;
                            })}
                        </Slider>
                    </Row>
                )}
            </Grid>
        );
    }
}
