import { cartConstants } from "../constants";

var storedCart = localStorage.getItem("item");
let cartItem = JSON.parse(storedCart);
const initialState = cartItem;

export const cart = (state = initialState, action) => {
    switch (action.type) {
        case cartConstants.ADD_CART:
            let newList = {
                ...state,
                [action.item.id]: action.item
            };
            localStorage.setItem("item", JSON.stringify(newList));
            return newList;

        case cartConstants.REMOVE_CART:
            delete state[action.id];
            localStorage.setItem("item", JSON.stringify({ ...state }));
            return { ...state };

        default:
            return state;
    }
};
