import React from "react";
import "./App.scss";
import ReactGA from "react-ga";
// import { BrowserRouter as Router, Route } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGhost, faTh } from "@fortawesome/free-solid-svg-icons";
import { BrowserRouter, Router, Switch, Route, Link } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Jobs from "./components/Jobs";
import FoodCategoryList from "./components/FoodCategoryList";
import RestaurantDetails from "./components/RestaurantDetails";
import Navbar from "./components/CustomNavbar";
import Footers from "./components/Footer";
import BillingForm from "./components/BillingForm";
import OtpVerify from "./components/OtpVerify";
import InstamojoRedirect from "./components/InstamojoRedirect";
import OrderHistory from "./components/OrderHistory";
import PaymentSuccess from "./components/PaymentSuccess";
import PrivacyPolicy from "./components/PrivacyPolicy";
import CookiesPolicy from "./components/CookiesPolicy";
import AddOnFoodList from "./components/AddOnFoodList";
import RazorpayPayment from "./components/RazorpayPayment";
library.add(faGhost);
library.add(faTh);
// library.add(FontAwesomeIcon);

ReactGA.initialize("UA-129589027-1");

const App = () => (
    <BrowserRouter>
        <div>
            <Navbar />
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/jobs" component={Jobs} />
            <Route path="/razorpaypayment" component={RazorpayPayment} />
            <Route path="/payment" component={InstamojoRedirect} />
            <Route
                path="/foodcategorylist/:id"
                name="foodcategorylist"
                component={FoodCategoryList}
            />
            <Route
                path="/restaurant/:id"
                name="restaurant"
                component={RestaurantDetails}
            />
            <Route path="/addOnFoodList" component={AddOnFoodList} />
            <Route path="/billingform" component={BillingForm} />
            <Route path="/otpverify" component={OtpVerify} />
            <Route path="/order" component={OrderHistory} />
            <Route path="/paymentsuccess/" component={PaymentSuccess} />
            <Route path="/privacypolicy/" component={PrivacyPolicy} />
            <Route path="/cookiespolicy/" component={CookiesPolicy} />
            <Footers />
        </div>
    </BrowserRouter>
);

export default App;
