import PropTypes from "prop-types";
import "./DropDownNav.css";
import { Link } from "react-router-dom";
import { Grid, Row, Col } from "react-bootstrap";
import { callBackend } from "./utils";
import React, { Component } from "react";
import * as Settings from "../Config.json";
class DropDownNav extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            category: [],
            restaurant: []
        };
        this.shiftCategory = this.shiftCategory.bind(this);
        this.handleToClose = this.props.handleToClose.bind(this);
    }

    componentDidMount() {
        /* this.setState({ hi: this.props.match.params.resId });*/
        // var id = this.props.match.params.id
       /* const header = new Headers();
        callBackend(
            Settings.baseurl + "/api/priority_rest/",
            response => {
                //const restFoods = response;
                this.setState({ restaurant: response });
            },
            header
        );*/

        fetch(Settings.baseurl +"/api/priority_rest/")
        .then(Response => Response.json())
        .then(findresponse => {
            
            this.setState({ restaurant: findresponse });

            fetch(Settings.baseurl +"/api/priority_foodcategory/")
            .then(Response => Response.json())
            .then(findresponse1 => {
                
                this.setState({ category: findresponse1 });
               
            });
           
        });

      /*  callBackend(
            Settings.baseurl + "/api/priority_foodcategory/",
            response => {
                //const restFoods = response;
                this.setState({ category: response });
            },
            header
        );*/
    }
    handleToClose(a) {
        return a;
    }

    shiftCategory(e) {
        this.props.show = false;
        e.preventDefault();
    }

    render() {
        const { show } = this.props;
        return (
            <div    onClick={() =>
                this.handleToClose(
                    false
                )
            }>
             
                {show ? (
                    <div    onClick={() =>
                                                                        this.handleToClose(
                                                                            false
                                                                        )
                                                                    } className="nav-overlay-background">
                        <div className="nav-overlay-content">
                            <Grid>
                                <Row>
                                    <Col md={6}>
                                        <div className="headings">
                                            <h4>Category</h4>
                                        </div>
                                        <div className="navdrop">
                                            <Row>
                                                {this.state.category
                                                    //.slice(0, 100)
                                                    .map(element => (
                                                        <Col
                                                            sm={4}
                                                            xs={4}
                                                            md={4}
                                                            lg={4}
                                                            className="category-list"
                                                            id={element[4]}
                                                        >
                                                            <Link
                                                                to={`/foodcategorylist/${
                                                                    element.id
                                                                }`}
                                                            >
                                                                <p
                                                                    className="foodCatRest"
                                                                    onClick={() =>
                                                                        this.handleToClose(
                                                                            false
                                                                        )
                                                                    }
                                                                >
                                                                    {" "}
                                                                    {
                                                                        element.food_catagory
                                                                    }
                                                                </p>
                                                            </Link>
                                                        </Col>
                                                    ))}
                                            </Row>
                                        </div>
                                    </Col>

                                    <Col md={6}>
                                        <div className="headings">
                                            <h4>Restaurants</h4>
                                        </div>
                                        <div className="navdrop">
                                            <Row>
                                                {this.state.restaurant
                                                    .slice(0)
                                                    .map(element => (
                                                        <Col
                                                            sm={4}
                                                            xs={4}
                                                            md={4}
                                                            lg={4}
                                                            className="category-list"
                                                            id={element[4]}
                                                        >
                                                            <Link
                                                                to={`/restaurant/${
                                                                    element.id
                                                                }`}
                                                            >
                                                                <p
                                                                    className="foodCatRest"
                                                                    onClick={() =>
                                                                        this.handleToClose(
                                                                            false
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        element.restaurant_name
                                                                    }
                                                                </p>
                                                            </Link>
                                                        </Col>
                                                    ))}
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

DropDownNav.propTypes = {
    show: PropTypes.bool.isRequired
};

export default DropDownNav;