import {
  Grid, Row, Col, Nav, Navbar, NavItem,
} from 'react-bootstrap';
import './Footer.css';
import { Link, Redirect } from 'react-router-dom';
import React, { Component } from 'react';
import lamzinglogo from '../images/lamzing.png';

class Footer extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
    this.toJobs = this.toJobs.bind(this);
  }

  toJobs() {
    return <Redirect to="/jobs" />;
  }

  render() {
    return (
      <div className="whole-footer">
        <div className="footer">
          <div style={{ textAlign: 'center' }} className="container">
            <Grid>
              <Row className="footer1">
                <Col md={8}>
                  <Nav className="footer-nav">
                    <NavItem to="/jobs" href="/jobs">
                                            Jobs
                    </NavItem>
                    <NavItem
                      to="/cookiespolicy"
                      href="/cookiespolicy"
                      style={{
                        color: 'white',
                      }}
                    >
                                            Cookies Policy
                    </NavItem>
                    <NavItem to="/" href="/">
                                            Legal Terms
                    </NavItem>
                    <NavItem
                      to="/privacypolicy"
                      href="/privacypolicy"
                    >
                                            Privacy Policy
                    </NavItem>
                  </Nav>
                </Col>

                <Col md={4}>
                  <p
                    style={{
                      fontSize: '14px',
                      color: 'white',
                    }}
                  >
                    <i
                      style={{
                        fontSize: '24px',
                        color: 'white',
                      }}
                      className="fa fa-home"
                    />
                    {' '}
                                        Wangkhei Ningthem Pukhri Mapal HMT
                                        Building Road
                  </p>
                  <p
                    style={{
                      fontSize: '14px',
                      color: 'white',
                      marginButtom: '20px',
                    }}
                  >
                    <a
                      style={{
                        fontSize: '14px',
                        color: 'white',
                      }}
                      href="tel:+917085063393"
                    >
                      <i
                        style={{
                          fontSize: '14px',
                          color: 'green',
                        }}
                        className="fa fa-phone"
                      />
                      {' '}
                                            +917085063393
                    </a>
                  </p>
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
        <div className="food1">
          <Grid>
            <Row>
              <Col md={3} sm={3} xs={3}>
                <a
                  className="iconsocial"
                  href="https://www.facebook.com/tasteofimphal/"
                >
                  <i
                    style={{
                      color: '#ffffff',
                      fontSize: '35px',
                    }}
                    className="fa fa-facebook-square"
                  />
                </a>
              </Col>
              <Col md={3} sm={3} xs={3}>
                <a
                  className="iconsocial"
                  href="https://www.instagram.com/tasteofimphal/"
                >
                  <i
                    style={{
                      color: '#ffffff',
                      fontSize: '35px',
                    }}
                    className="fa fa-instagram"
                  />
                </a>
              </Col>
              <Col md={3} sm={3} xs={3}>
                <a
                  className="iconsocial"
                  href="https://plus.google.com/116355714919691611124"
                >
                  <i
                    style={{
                      color: '#ffffff',
                      fontSize: '35px',
                    }}
                    className="fa fa-google-plus"
                    aria-hidden="true"
                  />
                </a>
              </Col>
              <Col md={3} sm={3} xs={3}>
                <a
                  className="iconsocial"
                  href="https://twitter.com/tasteofimphal"
                >
                  <i
                    style={{
                      color: '#ffffff',
                      fontSize: '35px',
                    }}
                    className="fa fa-twitter"
                  />
                </a>
              </Col>

              <Col md={12} sm={12} xs={12}>
                <p
                  style={{
                    fontSize: '14px',
                    color: 'white',
                    marginTop: '20px',
                  }}
                >
                                    Copyright © 2018 TasteOfImphal. All rights
                                    reserved. Powered by
                  {' '}
                  <span>
                    {' '}
                    <img
                      style={{
                        width: '100px',
                        height: '20px',
                      }}
                      alt="Lamzing Logo"
                      className=""
                      src={lamzinglogo}
                    />
                  </span>
                </p>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

export default Footer;
